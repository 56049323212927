import { useAuth0 } from '@auth0/auth0-react';
import { Logout } from '@mui/icons-material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {
	AppBar,
	Box,
	Collapse,
	Divider,
	Grid,
	IconButton,
	Paper,
	Toolbar,
	Tooltip,
	Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useSessionStorage } from 'shared/hooks/useSessionStorage';
import { useT4Layout } from 'shared/hooks/useT4Layout';
import { useWindowSize } from 'shared/hooks/useViewableWindowSize';
import { stonlyData } from 'stonly/functions';
import { useUser } from '../hooks/useUser';

export const stonlyIds = {
	stonlyLaunchpoint: 'stonly-launchpoint',
	adminMenuLaunchpoint: 'admin-menu-launchpoint',
	notificationsLaunchpoint: 'notifications-launchpoint',
};
export const dataTestIds = {
	stonlyLaunchpoint: 'stonly-launchpoint',
	adminMenuLaunchpoint: 'admin-menu-launchpoint',
};

export type LayoutProps = {
	appBar: JSX.Element;
	header?: JSX.Element;
	navElements?: JSX.Element | ReactNode;
	defaultOpen?: boolean;
	toggleable?: boolean;
};

export const Layout: FC<LayoutProps> = observer(
	({
		navElements,
		appBar,
		defaultOpen = true,
		toggleable = true,
		children,
	}) => {
		const { height: windowHeight } = useWindowSize();
		const { setOrganizationId } = useSessionStorage();
		const { logout } = useAuth0();
		const { name } = useUser();
		const { organizationCode, sideMenuOpen, setSideMenuOpen } = useT4Layout();

		const appBarRef = useRef<HTMLDivElement>();
		const drawerRef = useRef<HTMLDivElement>();

		const [height, setHeight] = useState(0);

		useEffect(() => {
			setHeight(windowHeight - (appBarRef.current?.clientHeight ?? 0));
		}, [windowHeight]);

		useEffect(() => {
			setSideMenuOpen(defaultOpen);
		}, [defaultOpen, setSideMenuOpen]);

		return (
			<Box sx={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
				<AppBar
					position="sticky"
					sx={(theme) => ({
						zIndex: theme.zIndex.drawer + 1,
						width: '100%',
					})}
				>
					<Toolbar component="div" ref={appBarRef as any} variant="dense">
						{appBar}
					</Toolbar>
				</AppBar>
				<Grid
					container
					wrap="nowrap"
					sx={{
						height: height,
						width: '100%',
					}}
				>
					<Grid item xs="auto">
						<Paper
							square
							sx={(theme) => ({
								backgroundColor: theme.palette.charcoal[700],
							})}
						>
							<Collapse
								ref={drawerRef}
								in={sideMenuOpen}
								orientation="horizontal"
								collapsedSize="3.5rem"
							>
								<Grid
									container
									item
									xs="auto"
									sx={{
										height: height,
										flexDirection: 'column',
										justifyContent: 'space-between',
										overflowY: 'auto',
										flexWrap: 'nowrap',
										scrollbarWidth: 'thin',
									}}
								>
									<Grid item xs="auto">
										{navElements}
									</Grid>
									<Grid item xs="auto">
										{toggleable && (
											<IconButton
												onClick={() => setSideMenuOpen(!sideMenuOpen)}
												size="small"
												sx={{
													padding: '8px',
													margin: '8px',
													'&:hover': {
														backgroundColor: 'rgba(255, 255, 255, 0.15)',
													},
												}}
											>
												{sideMenuOpen ? (
													<KeyboardDoubleArrowLeftIcon
														sx={() => ({
															color: 'white',
														})}
													/>
												) : (
													<KeyboardDoubleArrowRightIcon
														sx={() => ({
															color: 'white',
														})}
													/>
												)}
											</IconButton>
										)}

										<Divider
											sx={() => ({
												backgroundColor: 'white',
											})}
										/>
										<Grid
											container
											item
											xs="auto"
											wrap="nowrap"
											sx={{
												height: '3.75rem',
												justifyContent: 'space-between',
												paddingRight: '0rem',
												paddingY: '0.5rem',
												paddingLeft: sideMenuOpen ? '1rem' : '0.5rem',
											}}
										>
											{sideMenuOpen && (
												<Grid item xs="auto">
													<Typography
														noWrap
														sx={() => ({
															color: 'white',
														})}
													>
														{sideMenuOpen ? name : ''}
													</Typography>
													<Typography
														variant="body2"
														noWrap
														sx={() => ({
															color: 'white',
														})}
													>
														{organizationCode}
													</Typography>
												</Grid>
											)}
											<Grid item xs="auto" sx={{ display: 'flex' }}>
												<Tooltip title="Log out">
													<IconButton
														{...stonlyData({ id: 'logout-button' })}
														onClick={() => {
															setOrganizationId();
															logout({
																logoutParams: {
																	returnTo: window.location.origin,
																},
															});
														}}
														sx={{
															alignSelf: 'center',
															'&:hover': {
																backgroundColor: 'rgba(255, 255, 255, 0.15)',
															},
														}}
													>
														<Logout
															sx={() => ({
																display: 'flex',
																alignSelf: 'center',
																color: 'white',
															})}
														/>
													</IconButton>
												</Tooltip>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Collapse>
						</Paper>
					</Grid>
					<Grid item xs={true} sx={{ width: '100%', overflowY: 'auto' }}>
						{children}
					</Grid>
				</Grid>
			</Box>
		);
	},
);
