import { ListItemText, MenuItem } from '@mui/material';
import {
	GRID_CHECKBOX_SELECTION_FIELD,
	GridColDef,
	GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import { T4Toolbar } from 'shared/components/dataGrid/t4Toolbar';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { getCurrencyColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import {
	getOptionsMenuColDef,
	USER_PREFERENCES_FIELD_OPTIONS,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { CurrencySource, defaultCurrencyCode } from 'utilities/currencyUtils';
import {
	getReportedTransactionsColumnDefs,
	reportedTransactionsColumnVisibility,
} from '../../transactions/utilities';
import { useReconciliationsContext } from '../_providers/reconciliationsProvider';
import { TransactionListItem } from '../../transactions/models';
import AmountCurrencyTooltipGridCell from 'features/cash4/_shared/_components/AmountCurrencyTooltipGridCell';

export type ReportedTransactionsGridProps = {
	stonlyId: string;
	tableKey: string;
	loading?: boolean;
	reportedTransactions: TransactionListItem[];
	selectedReportedIds?: string[];
	setSelectedReportedIds?: (selectedReportedIds: string[]) => void;
};

export const ReportedTransactionsGrid: FC<ReportedTransactionsGridProps> =
	observer(
		({
			stonlyId,
			tableKey,
			loading = false,
			reportedTransactions,
			selectedReportedIds,
			setSelectedReportedIds,
		}) => {
			const { setSelectedTransaction } = useReconciliationsContext();
			const useCheckboxSelection = useMemo(
				() => selectedReportedIds !== undefined,
				[selectedReportedIds],
			);

			const onRowSelectionModelChangeHandler = useMemo(() => {
				if (useCheckboxSelection && setSelectedReportedIds) {
					return (projectedIds: GridRowSelectionModel) => {
						setSelectedReportedIds(projectedIds.map((x) => x.toString()));
					};
				}
			}, [setSelectedReportedIds, useCheckboxSelection]);

			const columns = useMemo<GridColDef[]>(() => {
				return getReportedTransactionsColumnDefs(
					{
						...getCurrencyColumnDefinition(),
						field: 'amount',
						headerName: 'Amount',
						valueGetter: (params) => params.row.number.accountCurrencyAmount,
						renderCell: (params) => {
							return AmountCurrencyTooltipGridCell({
								accountCurrencyAmount: params.row.number.accountCurrencyAmount,
								accountCurrencyCode: params.row.number.accountCurrencyCode,
								reportingCurrencyAmount:
									params.row.number.reportingCurrencyAmount || null,
								reportingCurrencyCode:
									params.row.number.reportingCurrencyCode ||
									defaultCurrencyCode,
								displayedCurrencyCode: CurrencySource.Account,
							});
						},
					},
					{
						field: 'currencyCode',
						headerName: 'Currency',
						valueGetter: (params) => params.row.number.accountCurrencyCode,
					},
					useCheckboxSelection
						? undefined
						: {
								...getOptionsMenuColDef((params) => {
									return (
										<OverflowMenu
											id={'c4-create-reconciliation-projected-item'}
										>
											<MenuItem
												onClick={() => setSelectedTransaction(params.row)}
											>
												<ListItemText>View Details</ListItemText>
											</MenuItem>
										</OverflowMenu>
									);
								}),
						  },
					undefined,
				);
			}, [setSelectedTransaction, useCheckboxSelection]);

			return (
				<UserPreferencesDataGrid<TransactionListItem>
					stonlyId={stonlyId}
					tableKey={tableKey}
					loading={loading}
					rows={reportedTransactions}
					hideFooter={false}
					showToolbar={true}
					showCustomViewButton
					pagination
					autoPageSize
					checkboxSelection={useCheckboxSelection}
					disableRowSelectionOnClick={useCheckboxSelection}
					rowSelectionModel={selectedReportedIds}
					onRowSelectionModelChange={onRowSelectionModelChangeHandler}
					initialState={{
						sorting: {
							sortModel: [{ field: 'date', sort: 'asc' }],
						},
						pinnedColumns: {
							left: [
								GRID_CHECKBOX_SELECTION_FIELD,
								USER_PREFERENCES_FIELD_OPTIONS,
							],
						},
					}}
					slots={{
						toolbar: T4Toolbar,
					}}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							hideExport: true,
							quickFilterProps: {
								variant: 'outlined',
								size: 'small',
							},
						},
					}}
					columns={columns}
					columnVisibilityModel={reportedTransactionsColumnVisibility}
				/>
			);
		},
	);
