import { Grid } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Payment } from 'modules/clients/apiGateway/payments4/payments';
import { FC, JSXElementConstructor } from 'react';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { USER_PREFERENCES_FIELD_OPTIONS } from 'shared/utilities/dataGrid/dataGridUtils';

type PaymentStatusesTabProps = {
	tableId: string;
	isLoading: boolean;
	payments: Payment[];
	columns: GridColDef[];
	gridNoRowsOverlay: JSXElementConstructor<any>;
	gridHeading: JSX.Element;
};

export const PaymentStatusesTab: FC<PaymentStatusesTabProps> = ({
	tableId,
	isLoading,
	payments,
	columns,
	gridNoRowsOverlay,
	gridHeading,
}) => {
	return (
		<div role="tabpanel" style={{ height: '100%' }}>
			<Grid
				container
				direction="column"
				wrap="nowrap"
				sx={{ height: '100%', gap: 2 }}
			>
				{gridHeading}

				<Grid item xs={true} sx={{ height: '50vh' }}>
					<ActuallyPrettyGoodDataGridWrapper>
						<UserPreferencesDataGrid<Payment>
							stonlyId={`payment-statuses-${tableId}`}
							tableKey={`payment-statuses-${tableId}`}
							loading={isLoading}
							columns={columns}
							rows={payments}
							showToolbar
							showCustomViewButton
							initialState={{
								sorting: {
									sortModel: [
										{
											field: 'valueDate',
											sort: 'desc',
										},
										{
											field: 'currentStatus',
											sort: 'asc',
										},
									],
								},
								pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
							}}
							slots={{ noRowsOverlay: gridNoRowsOverlay }}
						/>
					</ActuallyPrettyGoodDataGridWrapper>
				</Grid>
			</Grid>
		</div>
	);
};
