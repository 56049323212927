import { WarningAmber } from '@mui/icons-material';
import {
	Box,
	ListItemText,
	MenuItem,
	Tab,
	Tabs,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	GridColDef,
	GridRenderCellParams,
	GridValueFormatterParams,
	GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import {
	Payment,
	PaymentStatusTypes,
} from 'modules/clients/apiGateway/payments4/payments';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import {
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from 'shared/constants/cannotDisplayMessaging';
import { paths, validIdRegex } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import { getDateColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import {
	DataGridColumnWidths,
	getOptionsMenuColDef,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { formatCurrency } from 'utilities/currencyUtils';
import { useGetAllPayments } from '../hooks/usePayments';
import { PaymentDetailsDrawer } from '../paymentDetailsDrawer/paymentDetailsDrawer';
import {
	getPaymentApprovalStatusText,
	getPaymentStatusText,
} from '../utilities';
import { AllPaymentsTab } from './allPaymentsTab';
import { NeedsMyReviewTab } from './needsMyReviewTab';
import { RejectedPaymentsTab } from './rejectedPaymentsTab';
import { ReviewPaymentDrawer } from './reviewPaymentDrawer';

export const PaymentApprovalsPageRoutes: FC = () => {
	return (
		<Switch>
			<Route
				path={`${paths.payments4.paymentApprovals.href}/:paymentId`.concat(
					validIdRegex,
				)}
				exact
			>
				<PaymentApprovalsPage />
			</Route>
			<Route
				path={
					`${paths.payments4.paymentApprovals.href}/:paymentId`.concat(
						validIdRegex,
					) + '/review'
				}
				exact
			>
				<PaymentApprovalsPage />
			</Route>
			<Route path={paths.payments4.paymentApprovals.href} exact>
				<PaymentApprovalsPage />
			</Route>

			<Route>
				<CannotDisplay
					headingText={NOT_FOUND_MESSAGING.HEADING}
					bodyText={NOT_FOUND_MESSAGING.BODY}
					imageSrc={NOT_FOUND_MESSAGING.IMAGE}
					buttonText={RETURN_TO_HOME}
					buttonHref={paths.root.href}
				/>
			</Route>
		</Switch>
	);
};

export const PaymentApprovalsPage: FC = () => {
	// #region State
	const history = useHistory();
	const { paymentId: paymentIdQueryParam } = useParams<{
		paymentId: string | undefined;
	}>();
	const { payments4 } = useUser();
	const { enqueueSnackbar } = useSnackbar();

	const {
		isLoading,
		isFetching,
		data,
		error,
		refetch,
		dateRange,
		setDateRange,
	} = useGetAllPayments();

	const payments = useMemo(() => {
		return (
			data?.filter((payment) =>
				[
					PaymentStatusTypes[PaymentStatusTypes.Default],
					PaymentStatusTypes[PaymentStatusTypes.Created],
					PaymentStatusTypes[PaymentStatusTypes.Submitted],
					PaymentStatusTypes[PaymentStatusTypes.Rejected],
					PaymentStatusTypes[PaymentStatusTypes.Approved],
					PaymentStatusTypes[PaymentStatusTypes.Transmitted],
				].some((x) => x === payment.currentStatus),
			) ?? []
		);
	}, [data]);

	useEffect(() => {
		if (!isLoading && error?.message) {
			enqueueSnackbar(error.message, {
				variant: 'error',
			});
		}
	}, [isLoading, error, enqueueSnackbar]);

	const [paymentIdUnderReview, setPaymentIdUnderReview] = useState<
		string | null
	>(null);
	const [paymentIdToView, setPaymentIdToView] = useState<string | null>(null);

	/**
	 * if user
	 */
	useEffect(() => {
		if (paymentIdQueryParam !== undefined) {
			if (
				(history.location?.pathname as string | undefined)?.endsWith('review')
			) {
				setPaymentIdUnderReview(paymentIdQueryParam);
			} else {
				setPaymentIdToView(paymentIdQueryParam);
			}
		} else {
			setPaymentIdUnderReview(null);
			setPaymentIdToView(null);
		}
	}, [
		paymentIdQueryParam,
		paymentIdUnderReview,
		paymentIdToView,
		history.location,
	]);

	const [tabIndex, setTabIndex] = useState<number>(0);

	const columns: GridColDef[] = useMemo(() => {
		return [
			{
				...getOptionsMenuColDef((params: GridRenderCellParams<Payment>) => {
					return (
						<OverflowMenu id="payment-options-menu">
							<MenuItem
								onClick={() => {
									setPaymentIdToView(params.row.id);
									history.push(
										`${paths.payments4.paymentApprovals.href}/${params.row.id}`,
									);
								}}
							>
								<ListItemText>View Details</ListItemText>
							</MenuItem>
							{params.row.currentStatus ===
								PaymentStatusTypes[PaymentStatusTypes.Submitted] && (
								<Tooltip
									title={
										!payments4.isPaymentApprover
											? 'You do not have the correct permission to review payments. Contact your administrator if you require access.'
											: ''
									}
									placement="right"
									arrow
								>
									<span>
										<MenuItem
											onClick={() => {
												setPaymentIdUnderReview(params.row.id);
												history.push(
													`${paths.payments4.paymentApprovals.href}/${params.row.id}/review`,
												);
											}}
											disabled={!payments4.isPaymentApprover}
										>
											<ListItemText>Review Payment</ListItemText>
										</MenuItem>
									</span>
								</Tooltip>
							)}
						</OverflowMenu>
					);
				}),
			},
			{
				field: 'currentStatus',
				headerName: 'Current Status',
				description: 'Current Status',
				type: 'string',
				minWidth: 200,
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					getPaymentStatusText(params.value),
				renderCell: (params: GridRenderCellParams<Payment>) => {
					if (
						params.row.currentStatus ===
						PaymentStatusTypes[PaymentStatusTypes.Created]
					) {
						return (
							<Box
								sx={{
									display: 'flex',
									gap: 1,
									alignItems: 'center',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}
							>
								<WarningAmber color="warning" />
								<Typography variant="body2" noWrap>
									{params.value}
								</Typography>
							</Box>
						);
					}

					return params.value;
				},
				sortComparator: (v1: string, v2: string) =>
					PaymentStatusTypes[v1 as keyof typeof PaymentStatusTypes] -
					PaymentStatusTypes[v2 as keyof typeof PaymentStatusTypes],
			},
			{
				...getDateColumnDefinition(),
				field: 'valueDate',
				headerName: 'Value Date',
				description: 'Value Date',
			},
			{
				...getDateColumnDefinition(),
				field: 'createdOn',
				headerName: 'Created Date',
				description: 'Created Date',
			},
			{
				field: 'initiatorName',
				headerName: 'Initiator',
				description: 'Initiator',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.initiator.displayName,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'initiatorAccountName',
				headerName: 'Initiator Account',
				description: 'Initiator Account',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.initiator.accountDisplayName,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'initiatorAccountNumber',
				headerName: 'Initiator Account Number',
				description: 'Initiator Account Number',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.initiator.accountNumber,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'initiatorBank',
				headerName: 'Initiator Bank',
				description: 'Initiator Bank',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.initiator.bank.displayName,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'payeeName',
				headerName: 'Payee',
				description: 'Payee',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.payee.displayName,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'payeeAccountName',
				headerName: 'Payee Account',
				description: 'Payee Account',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.payee.accountDisplayName,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'payeeAccountNumber',
				headerName: 'Payee Account Number',
				description: 'Payee Account Number',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.payee.accountNumber,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'payeeBank',
				headerName: 'Payee Bank',
				description: 'Payee Bank',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.payee.bank.displayName,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'paymentType',
				headerName: 'Payment Type',
				description: 'Payment Type',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.paymentType,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'amount',
				headerName: 'Amount',
				description: 'Amount',
				type: 'number',
				valueFormatter: (params: GridValueFormatterParams) => {
					const row: Payment = params.api.getRow(params.id!)!;
					return formatCurrency(row.instructedAmount.value, {
						currency: row.instructedAmount.currencyCode ?? undefined,
						currencySign: 'accounting',
						currencyDisplay: 'narrowSymbol',
					})?.replace(row.instructedAmount.currencyCode!, '');
				},
				minWidth: DataGridColumnWidths.small,
			},
			{
				field: 'currency',
				headerName: 'CCY',
				description: 'CCY',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.instructedAmount.currencyCode,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'approvals',
				headerName: 'Approvals',
				description: 'Approvals',
				type: 'string',
				minWidth: 150,
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					getPaymentApprovalStatusText(params.row),
			},
		];
	}, [payments4, history]);

	// #endregion

	// #region Memoized Components

	const ReviewDrawer = useMemo(
		() => (
			<ReviewPaymentDrawer
				paymentId={paymentIdUnderReview}
				onClose={() => {
					setPaymentIdUnderReview(null);
					history.push(paths.payments4.paymentApprovals.href);
				}}
				refetch={refetch}
			/>
		),
		[paymentIdUnderReview, setPaymentIdUnderReview, history, refetch],
	);

	const PaymentRecordDetails = useMemo(
		() => (
			<PaymentDetailsDrawer
				paymentId={paymentIdToView}
				onClose={() => {
					setPaymentIdToView(null);
					history.push(paths.payments4.paymentApprovals.href);
				}}
				refetch={refetch}
			/>
		),
		[paymentIdToView, setPaymentIdToView, history, refetch],
	);

	const NeedsMyReview = useMemo(
		() => (
			<NeedsMyReviewTab
				tabIndex={tabIndex}
				setTabIndex={setTabIndex}
				dateRange={dateRange}
				setDateRange={setDateRange}
				payments={payments}
				isLoading={isLoading || isFetching}
				columns={columns}
			/>
		),
		[
			tabIndex,
			dateRange,
			setDateRange,
			payments,
			isLoading,
			isFetching,
			columns,
		],
	);

	const RejectedPayments = useMemo(
		() => (
			<RejectedPaymentsTab
				tabIndex={tabIndex}
				setTabIndex={setTabIndex}
				dateRange={dateRange}
				setDateRange={setDateRange}
				payments={payments}
				isLoading={isLoading || isFetching}
				columns={columns}
			/>
		),
		[
			tabIndex,
			dateRange,
			setDateRange,
			payments,
			isLoading,
			isFetching,
			columns,
		],
	);

	const AllPayments = useMemo(
		() => (
			<AllPaymentsTab
				tabIndex={tabIndex}
				dateRange={dateRange}
				setDateRange={setDateRange}
				payments={payments}
				isLoading={isLoading || isFetching}
				columns={columns}
			/>
		),
		[
			tabIndex,
			dateRange,
			setDateRange,
			payments,
			isLoading,
			isFetching,
			columns,
		],
	);

	// #endregion

	return (
		<T4View
			header={
				<PageHeader
					id={pageHeaderStonlyIds.payments4.paymentApprovalsPage}
					title="Approvals"
				/>
			}
		>
			<Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
				<Tabs
					indicatorColor="primary"
					value={tabIndex}
					onChange={(_, index) => setTabIndex(index)}
					sx={{ marginBottom: '1rem' }}
				>
					<Tab label="Needs My Review" tabIndex={0} />
					<Tab label="Rejected" tabIndex={1} />
					<Tab label="All Requests" tabIndex={2} />
				</Tabs>

				{NeedsMyReview}
				{RejectedPayments}
				{AllPayments}
			</Box>

			{ReviewDrawer}
			{PaymentRecordDetails}
		</T4View>
	);
};
