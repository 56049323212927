import { ExpandMore } from '@mui/icons-material';
import {
	Box,
	Button,
	Collapse,
	Divider,
	Grid,
	Typography,
} from '@mui/material';
import { T4Alert } from 'features/entity4/shared/components/atoms/t4Alert';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { useGetCategorization } from 'features/payments4/hooks/useCategorization';
import { PaymentPartyInformation } from 'features/payments4/paymentPartyInformationBox';
import {
	PaymentTemplate,
	PaymentTemplateStatusTypes,
} from 'modules/clients/apiGateway/payments4/paymentTemplates';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { getPaymentTemplateStatusText } from '../utilities';

export const TemplateStatusAndDetailsTab: FC<{
	template: PaymentTemplate | undefined;
}> = ({ template }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [collapseOpen, setCollapseOpen] = useState<boolean>(false);

	const {
		isCategorizationLoading,
		cashFlowClasses,
		glCodes,
		loadingCashFlowClassesError,
		loadingGlCodesError,
	} = useGetCategorization(!!template);
	useEffect(() => {
		if (loadingCashFlowClassesError || loadingGlCodesError) {
			enqueueSnackbar(
				'Unable to load Categorization information. Please try again later.',
				{
					variant: 'error',
				},
			);
		}
	}, [loadingCashFlowClassesError, loadingGlCodesError, enqueueSnackbar]);

	return (
		<div role="tabpanel">
			<Grid container sx={{ gap: 2 }}>
				<Grid container item xs={12} sx={{ gap: 2 }}>
					<Grid container item xs={12}>
						<Grid item xs={5}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								Current Status
							</Typography>
						</Grid>
						<Grid item xs sx={{ textAlign: 'right' }}>
							<Typography variant="body1">
								{getPaymentTemplateStatusText(template?.currentStatus ?? null)}
							</Typography>
						</Grid>
					</Grid>
					<Grid container item xs={12}>
						<Grid item xs={5}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								Template Name
							</Typography>
						</Grid>
						<Grid item xs sx={{ textAlign: 'right' }}>
							<Typography variant="body1">{template?.name}</Typography>
						</Grid>
					</Grid>
					<Grid container item xs={12}>
						<Grid item xs={5}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								Payment Type
							</Typography>
						</Grid>
						<Grid item xs sx={{ textAlign: 'right' }}>
							<Typography variant="body1">{template?.paymentType}</Typography>
						</Grid>
					</Grid>
					<Grid container item xs={12}>
						<Grid item xs={5}>
							<Typography variant="body1" sx={{ fontWeight: 500 }}>
								Payment Currency
							</Typography>
						</Grid>
						<Grid item xs sx={{ textAlign: 'right' }}>
							<Typography variant="body1">{template?.currencyCode}</Typography>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<T4TextFieldV2
							label="Payment Information"
							value={template?.referenceData ?? ''}
							minRows={4}
							maxRows={4}
							multiline
							InputProps={{ readOnly: true }}
						/>
					</Grid>
					{template?.currentStatus ===
						PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Rejected] && (
						<Grid container item xs={12}>
							<T4Alert
								severity="error"
								sx={{
									'&.MuiPaper-root': {
										height: '100%',
										width: '100%!important',
									},
								}}
							>
								<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
									<Typography variant="body2" fontWeight={500}>
										Rejection Reason
									</Typography>
									<Typography variant="caption">
										{
											template.statusHistory.find(
												(x) =>
													x.templateVersion === template.templateVersion &&
													x.paymentTemplateStatusType ===
														PaymentTemplateStatusTypes[
															PaymentTemplateStatusTypes.Rejected
														],
											)?.reason
										}
									</Typography>
								</Box>
							</T4Alert>
						</Grid>
					)}
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Button
						onClick={() => setCollapseOpen(!collapseOpen)}
						endIcon={
							<ExpandMore
								sx={{
									transition: 'rotate .25s',
									rotate: collapseOpen ? '180deg' : '0deg',
								}}
							/>
						}
						variant="text"
						color="secondary"
					>
						{collapseOpen ? 'Collapse Details' : 'Expand Details'}
					</Button>
				</Grid>

				<Collapse in={collapseOpen}>
					<Grid container sx={{ gap: 2 }}>
						<PaymentPartyInformation
							party={template?.initiator ?? null}
							partyType="Initiator"
							isCategorizationLoading={isCategorizationLoading}
							cashFlowClasses={cashFlowClasses}
							glCodes={glCodes}
						/>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<PaymentPartyInformation
							party={template?.payee ?? null}
							partyType="Payee"
							isCategorizationLoading={isCategorizationLoading}
							cashFlowClasses={cashFlowClasses}
							glCodes={glCodes}
						/>
					</Grid>
				</Collapse>
			</Grid>
		</div>
	);
};
