/* eslint-disable mobx/missing-observer */
import { ContentCopy, Share } from '@mui/icons-material';
import { IconButton, SxProps, Theme, Tooltip } from '@mui/material';
import { FC, JSXElementConstructor, useMemo, useState } from 'react';

export type CopyToClipboardIconProps = {
	// value to be copied to clipboard
	value: string;

	// icon override
	icon?: JSXElementConstructor<any>;

	// icon button size
	iconButtonSize?: 'small' | 'medium' | 'large';

	// icon height/width set via fontSize property on icon
	iconSize?: string;

	// styles applied to the tooltip
	tooltipSx?: SxProps<Theme>;

	// determines the placement of the tooltip relative to the icon button
	tooltipPlacement?:
		| 'top'
		| 'bottom'
		| 'right'
		| 'left'
		| 'top-start'
		| 'top-end'
		| 'bottom-start'
		| 'bottom-end'
		| 'right-start'
		| 'right-end'
		| 'left-start'
		| 'left-end';

	// initial text diplayed in the icon button tooltip
	initialTooltipText?: string;
};

export const CopyToClipboardIconButton: FC<CopyToClipboardIconProps> = ({
	value,
	icon,
	iconButtonSize,
	iconSize,
	tooltipSx,
	tooltipPlacement,
	initialTooltipText = 'Copy details.',
}) => {
	const [tooltipText, setTooltipText] = useState<string>(initialTooltipText);

	const copyToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(value ?? '');
			setTooltipText('Copied!');
		} catch (err) {
			setTooltipText('Failed to copy!');
		} finally {
			setTimeout(() => setTooltipText(initialTooltipText), 2000);
		}
	};

	const overrideIcon = useMemo(() => {
		if (icon) {
			const IconComp = icon;
			return <IconComp sx={{ fontSize: iconSize }} />;
		}
	}, [icon, iconSize]);

	return (
		<Tooltip
			onClose={() => setTooltipText(initialTooltipText)}
			title={tooltipText}
			placement={tooltipPlacement}
			sx={tooltipSx}
		>
			<IconButton size={iconButtonSize} onClick={copyToClipboard}>
				{overrideIcon ?? <ContentCopy sx={{ fontSize: iconSize }} />}
			</IconButton>
		</Tooltip>
	);
};

export const ShareLinkIconButton: FC<
	Omit<CopyToClipboardIconProps, 'icon' | 'initialTooltipText'>
> = (props) => {
	return (
		<CopyToClipboardIconButton
			{...props}
			icon={Share}
			initialTooltipText="Copy link."
		/>
	);
};
