import { Add, Balance, CurrencyExchange, SwapHoriz } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
	Button,
	ButtonGroup,
	ClickAwayListener,
	Grid,
	Grow,
	Link,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Tab,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	GridColDef,
	GridColumnVisibilityModel,
	GridRowClassNameParams,
} from '@mui/x-data-grid-pro';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { LegalEntityGroupsFilter } from 'features/entity4/shared/components/legalEntityGroupsFilter';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import {
	ForecastedTransaction,
	ProjectedTransaction,
} from 'modules/clients/customer-api/src/api/cash4';
import moment, { Moment } from 'moment';
import { enqueueSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import {
	ConfirmationDialog,
	IConfirmationDialogProps,
} from 'shared/components/confirmationDialog';
import { NoRowsOverlay } from 'shared/components/dataGrid/noRowsOverlay';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import T4DateRangePicker from 'shared/components/dateRangePicker/t4DateRangePicker';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { paths } from 'shared/constants/paths';
import { useSessionStorage } from 'shared/hooks/useSessionStorage';
import { useT4FeatureFlags } from 'shared/hooks/useT4FeatureFlags';
import { useUser } from 'shared/hooks/useUser';
import { useLegalEntityGroups } from 'shared/providers/legalEntityGroupsProvider';
import { brandColors } from 'shared/theme/brandColors';
import { getCurrencyColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import { getQueryParam } from 'shared/utilities/navigationUtilities';
import { stonlyData } from 'stonly/functions';
import {
	CurrencySource,
	defaultCurrencyCode,
	formatCurrency,
} from 'utilities/currencyUtils';
import { C4ProjectedEditModal } from '../_shared/_components/c4ProjectedEditModal';
import { useAccountIntegrationsContext } from '../accountIntegrations/providers/accountIntegrationsProviders';
import { C4TransactionsDrawer } from '../reconciliations/_components/c4TransactionsDrawer';
import { ReconciliationDrawer } from '../reconciliations/_components/reconciliationDrawer';
import { useReconciliationsContext } from '../reconciliations/_providers/reconciliationsProvider';
import T4Drawer from '../shared/components/T4SideDrawer/T4DrawerShell';
import {
	Action,
	Actions,
	ActionsEnum,
} from '../shared/components/T4ActionMenu';
import { ProjectedTransactionDrawer } from './components/ProjectedTransactions/ProjectedTransactionDrawer';
import { ProjectionViewDrawer } from './components/ProjectedTransactions/ProjectionViewDrawer';
import { CurrencyOption, TransactionListItem } from './models';
import { useTransactions } from './providers/useTransactions';
import {
	deleteForecastedTransactionApiCall,
	deleteProjectedTransactionApiCall,
} from './services';
import {
	getReportedTransactionsColumnDefs,
	getProjectedTransactionsColumnDefs,
	getForecastedTransactionsColumnDefs,
	reportedTransactionsColumnVisibility,
	projectedTransactionsColumnVisibility,
	forecastedTransactionsColumnVisibility,
	stonlyIds as stonly,
} from './utilities/index';
import { ArrowDropDownIcon, DateRange } from '@mui/x-date-pickers-pro';
import {
	normalizeReconciliationStatus,
	ReconciliationStatus,
} from '../reconciliations/_hooks/useReconciliationCalculations';
import { useRowSelectionCalculations } from '../_shared/_utilities/useRowSelectionCalculations';
import { T4Alert } from 'features/entity4/shared/components/atoms/t4Alert';
import { C4AlertBold } from '../reconciliations/_components/c4AlertBold';
import AmountCurrencyTooltipGridCell from '../_shared/_components/AmountCurrencyTooltipGridCell';
import React from 'react';
import { ForecastedTransactionDrawer } from './components/ForecastedTransactions/ForecastedTransactionDrawer';
export const dataTestIds = {
	categorizationInfoModal: 'categorization-info-modal',
	createProjectedTransactionButton: 'create-projected-item-button',
};

export type TransactionPageTab = 'reported' | 'projected' | 'forecasted';

export const TransactionsPage: FC = observer(() => {
	const { forecastedTransactionsEnabled } = useT4FeatureFlags();
	const user = useUser();
	const { legalEntityGroupIds } = useLegalEntityGroups();
	const history = useHistory();
	const queryClient = useQueryClient();
	const { transactionId, projectionId } = useParams<{
		transactionId: string;
		projectionId: string;
	}>();

	const {
		dateRange,
		handleDateRangeChange,
		defaultDateRange,
		fetchTransactions,
		fetchProjectedTransactions,
		fetchForecastedTransactions,
		currencyOption,
		configurations,
		handleCurrencyOptionChange,
		deleteReportedTransactionApiCall,
	} = useTransactions();

	const { setTransactionStartDate, setTransactionEndDate } =
		useSessionStorage();

	const {
		startReconciliationCreation,
		viewReconciliation,
		reconciliationQueryContext: { refetch: refetchReconciliations },
		setDateRange,
	} = useReconciliationsContext();

	const {
		updateCurrentDay,
		getCurrentDayRefreshStatus,
		getCurrentDayRefreshDate,
	} = useAccountIntegrationsContext();

	const {
		forecastedTransactionStartDate,
		forecastedTransactionEndDate,
		setForecastedTransactionStartDate,
		setForecastedTransactionEndDate,
	} = useSessionStorage();

	const [forecastedTransactionDateRange, setForecastedTransactionDateRange] =
		useState<DateRange<Moment>>([
			forecastedTransactionStartDate,
			forecastedTransactionEndDate,
		]);

	const handleForecastedTransactionDateRangeChange = useCallback(
		(dateRange: DateRange<Moment>) => {
			setForecastedTransactionDateRange(dateRange);

			// Update session variables
			if (dateRange[0]) {
				setForecastedTransactionStartDate(dateRange[0]);
			}
			if (dateRange[1]) {
				setForecastedTransactionEndDate(dateRange[1]);
			}
		},
		[setForecastedTransactionEndDate, setForecastedTransactionStartDate],
	);

	const {
		data: forecastedTransactions,
		isFetching: isForecastedTransactionsFetching,
	} = useQuery(
		[
			'forecasted-transactions',
			forecastedTransactionDateRange,
			legalEntityGroupIds,
		],
		() =>
			fetchForecastedTransactions(
				forecastedTransactionDateRange,
				legalEntityGroupIds,
			),
		{
			initialData: [],
			refetchOnWindowFocus: false,
		},
	);

	const {
		projectedTransactionStartDate,
		projectedTransactionEndDate,
		setProjectedTransactionStartDate,
		setProjectedTransactionEndDate,
	} = useSessionStorage();

	const [projectedTransactionDateRange, setProjectedTransactionDateRange] =
		useState<DateRange<Moment>>([
			projectedTransactionStartDate,
			projectedTransactionEndDate,
		]);

	const handleProjectedTransactionDateRangeChange = useCallback(
		(dateRange: DateRange<Moment>) => {
			setProjectedTransactionDateRange(dateRange);

			// Update session variables
			if (dateRange[0]) {
				setProjectedTransactionStartDate(dateRange[0]);
			}
			if (dateRange[1]) {
				setProjectedTransactionEndDate(dateRange[1]);
			}
		},
		[setProjectedTransactionEndDate, setProjectedTransactionStartDate],
	);

	const {
		data: projectedTransactions,
		isFetching: isProjectedTransactionsFetching,
	} = useQuery(
		[
			'projected-transactions',
			projectedTransactionDateRange,
			legalEntityGroupIds,
		],
		() =>
			fetchProjectedTransactions(
				projectedTransactionDateRange,
				legalEntityGroupIds,
			),
		{
			initialData: [],
			refetchOnWindowFocus: false,
		},
	);

	const [projectedTransaction, setProjectedTransaction] =
		useState<ProjectedTransaction>();
	const [forecastedTransaction, setForecastedTransaction] =
		useState<ForecastedTransaction>();
	const [transaction, setTransaction] = useState<TransactionListItem>();
	const [openTransactionDrawer, setOpenTransactionDrawer] =
		useState<boolean>(false);
	const [confirmationModalLoading, setConfirmationModalLoading] =
		useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [selectedDialog, setSelectedDialog] =
		useState<IConfirmationDialogProps>({} as IConfirmationDialogProps);
	const [openProjectionDrawer, setOpenProjectionDrawer] = useState(false);
	const [isEditWarningOpen, setIsEditWarningOpen] = useState(false);

	const [isProjectedCreateEditDrawerOpen, setIsProjectedCreatedEditDrawerOpen] =
		useState<boolean>(false);

	const [
		isForecastedCreateEditDrawerOpen,
		setIsForecastedCreatedEditDrawerOpen,
	] = useState<boolean>(false);

	const [selectedProjectedIds, setSelectedProjectedIds] = useState<string[]>(
		[],
	);
	const [selectedReportedTransactions, setSelectedReportedTransactions] =
		useState<TransactionListItem[]>([]);

	const [transactionPopperOpen, setTransactionPopperOpen] =
		React.useState(false);

	const handleCloseTransactionDrawer = useCallback(() => {
		setOpenTransactionDrawer(false);
		history.push(paths.cash4.transactions.href);
	}, [history]);

	const handleCloseProjectionDrawer = useCallback(() => {
		setOpenProjectionDrawer(false);
		history.push(`${paths.cash4.transactions.href}?tab=projected`);
	}, [history]);

	const handleOpenTransactionDrawer = () => {
		setOpenTransactionDrawer(true);
	};

	const handleOpenProjectionDrawer = useCallback(() => {
		setOpenProjectionDrawer(true);
	}, []);

	const handleOpenDialog = () => {
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleCreateProjectedTransactionClick = useCallback(() => {
		setProjectedTransaction(undefined);
		setIsProjectedCreatedEditDrawerOpen(true);
	}, []);

	const handleCreateForecastedTransactionClick = useCallback(() => {
		setForecastedTransaction(undefined);
		setIsForecastedCreatedEditDrawerOpen(true);
	}, []);

	const handleProjectedViewClick = useCallback(
		(projection: ProjectedTransaction) => {
			setProjectedTransaction(projection);
			history.push(
				`${paths.cash4.projectedTransactions.href}/${
					projection.id
				}?tab=${'projected'}`,
			);
			handleOpenProjectionDrawer();
		},
		[handleOpenProjectionDrawer, history],
	);

	const handleProjectedEditClick = useCallback(
		(projection: ProjectedTransaction) => {
			setProjectedTransaction(projection);
			if (projection.reconciliationId) {
				setIsEditWarningOpen(true);
			} else {
				setIsProjectedCreatedEditDrawerOpen(true);
				setOpenProjectionDrawer(false);
			}
		},
		[setIsProjectedCreatedEditDrawerOpen, setIsEditWarningOpen],
	);

	const deleteTransaction = (data: { transactionId: string }) => {
		const { transactionId } = data;
		setConfirmationModalLoading(true);
		return deleteReportedTransactionApiCall(transactionId);
	};

	const mutation = useMutation(deleteTransaction, {
		onSuccess: (data, variables) => {
			if (data) {
				handleCloseDialog();
				queryClient.invalidateQueries(['transactions']);
				enqueueSnackbar('Transaction deleted successfully.', {
					variant: 'success',
				});
			} else {
				enqueueSnackbar('Error deleting transaction.', {
					variant: 'error',
				});
			}
			setConfirmationModalLoading(false);
		},
	});

	const handleDeleteReportedTransaction = useCallback(
		(transactionId: string) => {
			return mutation.mutate({ transactionId: transactionId });
		},
		[mutation],
	);

	const handleReportedTransactionDeleteClick = useCallback(
		(transaction: TransactionListItem) => {
			setSelectedDialog({
				title: 'Delete reported transaction?',
				text: 'This reported transaction will be permanently deleted from the system. This action cannot be undone.',
				primaryButtonText: 'DELETE',
				onPrimaryButtonClick: () => {
					try {
						handleDeleteReportedTransaction(transaction.id);
						setOpenTransactionDrawer(false);
					} catch {}
				},
				secondaryButtonText: 'CANCEL',
				onSecondaryButtonClick: handleCloseDialog,
			} as IConfirmationDialogProps);
			handleOpenDialog();
		},
		[handleDeleteReportedTransaction],
	);

	const handleReportedTransactionReconcileClick = useCallback(
		(transaction: TransactionListItem) => {
			setSelectedReportedTransactions((t) => [...t, ...[transaction]]);
		},
		[],
	);

	useEffect(() => {
		const transactionDateRange = [
			selectedReportedTransactions
				.map((ct) => moment(ct.date))
				.sort((a, b) => a.diff(b))[0],
			selectedReportedTransactions
				.map((ct) => moment(ct.date))
				.sort((a, b) => a.diff(b))
				[selectedReportedTransactions.length - 1]?.add(1, 'day'),
		];
		if (!!transactionDateRange[0] && !!transactionDateRange[1]) {
			setDateRange(transactionDateRange as DateRange<Moment>);
			startReconciliationCreation();
		}
	}, [selectedReportedTransactions, setDateRange, startReconciliationCreation]);

	const deleteProjectedTransaction = (data: {
		ProjectedTransactionId: string;
	}) => {
		const { ProjectedTransactionId } = data;
		return deleteProjectedTransactionApiCall(ProjectedTransactionId);
	};

	const projectedTransactionMutation = useMutation(deleteProjectedTransaction, {
		onSuccess: () => {
			queryClient.invalidateQueries(['projected-transactions']);
			enqueueSnackbar('The projected transaction was successfully deleted!', {
				variant: 'success',
			});
		},
		onError: () => {
			enqueueSnackbar(
				'An error occurred and the projected transaction could not be deleted.',
				{
					variant: 'error',
					autoHideDuration: 10000,
				},
			);
		},
	});

	const handleDeleteProjectedTransaction = useCallback(
		(projectedTransactionId: string) => {
			setConfirmationModalLoading(true);
			try {
				if (projectedTransactionId) {
					projectedTransactionMutation.mutate({
						ProjectedTransactionId: projectedTransactionId,
					});
				}
				handleCloseDialog();
				if (openProjectionDrawer) {
					handleCloseProjectionDrawer();
				}
			} catch {
			} finally {
				setConfirmationModalLoading(false);
			}
		},
		[
			handleCloseProjectionDrawer,
			openProjectionDrawer,
			projectedTransactionMutation,
		],
	);

	const handleProjectedTransactionDeleteClick = useCallback(
		(row: ProjectedTransaction) => {
			setSelectedDialog({
				title: 'Delete projected transaction?',
				text: 'This projected transaction will be permanently deleted from the system. This action cannot be undone.',
				primaryButtonText: 'DELETE',
				onPrimaryButtonClick: () => {
					handleDeleteProjectedTransaction(row.id);
				},
				secondaryButtonText: 'CANCEL',
				onSecondaryButtonClick: handleCloseDialog,
			} as IConfirmationDialogProps);

			handleOpenDialog();
		},
		[handleDeleteProjectedTransaction],
	);

	const handleProjectedTransactionReconcileClick = useCallback(
		(row: ProjectedTransaction) => {
			setSelectedProjectedIds([row.id]);
			startReconciliationCreation();
		},
		[startReconciliationCreation],
	);

	const anchorRef = React.useRef<HTMLDivElement>(null);

	const createTransactionButton = useMemo(
		() => (
			<>
				<ButtonGroup ref={anchorRef}>
					<Button
						startIcon={<Add />}
						endIcon={<ArrowDropDownIcon />}
						type="button"
						variant="outlined"
						color="primary"
						data-testid={dataTestIds.createProjectedTransactionButton}
						{...stonlyData({
							id: stonly.projectedTransactionsCreateButton,
						})}
						onClick={() => {
							setTransactionPopperOpen(!transactionPopperOpen);
						}}
					>
						Transaction
					</Button>
				</ButtonGroup>
				<Popper
					sx={{ zIndex: 1 }}
					open={transactionPopperOpen}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					disablePortal
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin:
									placement === 'bottom' ? 'center top' : 'center bottom',
							}}
						>
							<Paper>
								<ClickAwayListener
									onClickAway={() => {
										setTransactionPopperOpen(!transactionPopperOpen);
									}}
								>
									<MenuList id="split-button-menu" autoFocusItem>
										{forecastedTransactionsEnabled && (
											<MenuItem
												onClick={() => handleCreateForecastedTransactionClick()}
											>
												Forecasted Transaction
											</MenuItem>
										)}

										<MenuItem
											onClick={() => handleCreateProjectedTransactionClick()}
										>
											Projected Transaction
										</MenuItem>
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</>
		),
		[
			forecastedTransactionsEnabled,
			handleCreateForecastedTransactionClick,
			handleCreateProjectedTransactionClick,
			transactionPopperOpen,
		],
	);

	const ProjectedCreateEditDrawer = useMemo(
		() => (
			<ProjectedTransactionDrawer
				isOpen={isProjectedCreateEditDrawerOpen}
				projectedTransaction={projectedTransaction}
				onClose={() => {
					setIsProjectedCreatedEditDrawerOpen(false);
					setProjectedTransaction(undefined);
				}}
				onSubmit={() => {
					queryClient.invalidateQueries(['projected-transactions']);
					refetchReconciliations();
				}}
			/>
		),
		[
			isProjectedCreateEditDrawerOpen,
			projectedTransaction,
			queryClient,
			refetchReconciliations,
		],
	);

	const ForecastedCreateEditDrawer = useMemo(
		() => (
			<ForecastedTransactionDrawer
				isOpen={isForecastedCreateEditDrawerOpen}
				forecastedTransaction={forecastedTransaction}
				onClose={() => {
					setIsForecastedCreatedEditDrawerOpen(false);
					setForecastedTransaction(undefined);
				}}
				onSubmit={() => {
					queryClient.invalidateQueries(['forecasted-transactions']);
					refetchReconciliations();
				}}
			/>
		),
		[
			forecastedTransaction,
			isForecastedCreateEditDrawerOpen,
			queryClient,
			refetchReconciliations,
		],
	);

	useEffect(() => {
		dateRange[0] && setTransactionStartDate(dateRange[0]);
		dateRange[1] && setTransactionEndDate(dateRange[1]);
	}, [dateRange, setTransactionEndDate, setTransactionStartDate]);

	const { data, isFetching, error } = useQuery(
		['transactions', dateRange, legalEntityGroupIds],
		() => fetchTransactions(dateRange, legalEntityGroupIds),
		{
			initialData: [],
			refetchOnWindowFocus: false,
		},
	);

	const { data: currentDayRefreshStatus } = useQuery(
		['currentDayRefreshStatus'],
		() => getCurrentDayRefreshStatus(),
		{
			refetchOnWindowFocus: false,
			initialData: false,
		},
	);

	const { data: lastRefreshedDate } = useQuery(
		['lastRefreshedDate'],
		() => getCurrentDayRefreshDate(),
		{
			refetchOnWindowFocus: false,
			initialData: '',
		},
	);

	useEffect(() => {
		if (transactionId) {
			handleOpenTransactionDrawer();
		}
	}, [transactionId]);

	useEffect(() => {
		if (projectionId) {
			handleOpenProjectionDrawer();
		}
	}, [handleOpenProjectionDrawer, projectionId]);

	const tabValue = useMemo(() => {
		return getQueryParam(history.location.search, 'tab') ?? 'reported';
	}, [history.location.search]);

	const handleTransactionViewClick = useCallback(
		(transaction: TransactionListItem) => {
			setTransaction(transaction);
			history.push(`${paths.cash4.transactions.href}/${transaction.id}`);
			if (openTransactionDrawer) {
				handleCloseTransactionDrawer();
			} else if (openProjectionDrawer) {
				handleCloseProjectionDrawer();
			}
		},
		[
			handleCloseProjectionDrawer,
			handleCloseTransactionDrawer,
			history,
			openProjectionDrawer,
			openTransactionDrawer,
		],
	);

	// #region Forecasted Transactions Action Functions

	const [openForecastedDrawer, setOpenForecastedDrawer] = useState(false);

	const handleCloseForecastedDrawer = useCallback(() => {
		setOpenForecastedDrawer(false);
		history.push(`${paths.cash4.transactions.href}?tab=forecasted`);
	}, [history]);

	const deleteForecastedTransaction = (data: {
		ForecastedTransactionId: string;
	}) => {
		const { ForecastedTransactionId } = data;
		return deleteForecastedTransactionApiCall(ForecastedTransactionId);
	};

	const forecastedTransactionMutation = useMutation(
		deleteForecastedTransaction,
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['forecasted-transactions']);
				enqueueSnackbar(
					'The forecasted transaction was successfully deleted!',
					{
						variant: 'success',
					},
				);
			},
			onError: () => {
				enqueueSnackbar(
					'An error occurred and the forecasted transaction could not be deleted.',
					{
						variant: 'error',
						autoHideDuration: 10000,
					},
				);
			},
		},
	);

	const handleDeleteForecastedTransaction = useCallback(
		(forecastedTransactionId: string) => {
			setConfirmationModalLoading(true);
			try {
				if (forecastedTransactionId) {
					forecastedTransactionMutation.mutate({
						ForecastedTransactionId: forecastedTransactionId,
					});
				}
				handleCloseDialog();
				if (openForecastedDrawer) {
					handleCloseForecastedDrawer();
				}
			} catch {
			} finally {
				setConfirmationModalLoading(false);
			}
		},
		[
			handleCloseForecastedDrawer,
			openForecastedDrawer,
			forecastedTransactionMutation,
		],
	);

	const handleForecastedTransactionDeleteClick = useCallback(
		(row: ForecastedTransaction) => {
			setSelectedDialog({
				title: 'Delete forecasted transaction?',
				text: 'This forecasted transaction will be permanently deleted from the system. This action cannot be undone.',
				primaryButtonText: 'DELETE',
				onPrimaryButtonClick: () => {
					handleDeleteForecastedTransaction(row.id);
				},
				secondaryButtonText: 'CANCEL',
				onSecondaryButtonClick: handleCloseDialog,
			} as IConfirmationDialogProps);

			handleOpenDialog();
		},
		[handleDeleteForecastedTransaction],
	);

	// #endregion Forecasted Transactions Action Functions

	const handleReconciliationSuccess = useCallback(() => {
		queryClient.invalidateQueries(['transactions', 'projected-transactions']);
		setOpenTransactionDrawer(false);
	}, [queryClient]);

	const reconciliationDrawer = useMemo(() => {
		return (
			<ReconciliationDrawer
				onSuccess={handleReconciliationSuccess}
				onClose={() => {
					setSelectedProjectedIds([]);
					setSelectedReportedTransactions([]);
				}}
				projectedTransactionIds={selectedProjectedIds}
				reportedTransactionProps={
					selectedReportedTransactions?.length > 0
						? {
								reportedTransactionIds: selectedReportedTransactions.map(
									(ct) => ct.id,
								),
								dateRange: [
									selectedReportedTransactions
										.map((ct) => moment(ct.date))
										.sort((a, b) => a.diff(b))[0],
									selectedReportedTransactions
										.map((ct) => moment(ct.date))
										.sort((a, b) => a.diff(b))
										[selectedReportedTransactions.length - 1]?.add(1, 'day'),
								],
						  }
						: undefined
				}
			/>
		);
	}, [
		handleReconciliationSuccess,
		selectedProjectedIds,
		selectedReportedTransactions,
	]);

	const reportedTransactionColumns = useMemo<GridColDef[]>(() => {
		return getReportedTransactionsColumnDefs(
			{
				...getCurrencyColumnDefinition(),
				field: 'number',
				headerName: 'Amount',
				valueGetter: (params) => {
					if (currencyOption.id === 'original') {
						return params.row.number.accountCurrencyAmount;
					} else {
						return params.row.number.reportingCurrencyAmount;
					}
				},
				renderCell: (params) => {
					return AmountCurrencyTooltipGridCell({
						accountCurrencyAmount: params.row.number.accountCurrencyAmount,
						accountCurrencyCode: params.row.number.accountCurrencyCode,
						reportingCurrencyAmount: params.row.number.reportingCurrencyAmount,
						reportingCurrencyCode:
							params.row.number.reportingCurrencyCode || defaultCurrencyCode,
						displayedCurrencyCode:
							currencyOption.id === 'reporting'
								? CurrencySource.Reporting
								: CurrencySource.Account,
					});
				},
			},
			{
				field: 'currency',
				headerName: 'Currency',
				renderCell: (params) => {
					return currencyOption.id === 'original'
						? params.row.currency?.toUpperCase()
						: params.row.number.reportingCurrencyCode;
				},
			},
			{
				field: 'actions',
				headerName: '',
				width: 25,
				renderCell: (params) => {
					return (
						<Actions
							objecttype="Transaction"
							stonlyId={stonly.projectedTransactionsRowContextMenu}
							actions={[
								{
									action: ActionsEnum.view,
									callback: () => handleTransactionViewClick(params.row),
								},
								...(user.cash4.isAuthor
									? [
											{
												action: ActionsEnum.delete,
												isDisabled: params.row.reconciliationRecordId
													? true
													: undefined,
												isDisabledTooltipText:
													'This record is associated with a reconciliation and cannot be deleted. Edit reconciliation to remove association.',
												callback: () =>
													handleReportedTransactionDeleteClick(params.row),
											},
									  ]
									: []),
								...(user.cash4.isAuthor && !params.row.reconciliationRecordId
									? [
											{
												action: ActionsEnum.reconcile,
												callback: () =>
													handleReportedTransactionReconcileClick(params.row),
											},
									  ]
									: []),
							]}
							id="transactionId-more-menu"
						/>
					);
				},
				resizable: false,
				sortable: false,
				disableColumnMenu: true,
				filterable: false,
				disableExport: true,
				hideable: false,
				disableReorder: true,
			},
			{
				field: 'reconciliationStatus',
				headerName: 'Reconciliation Status',
				valueGetter: (params) => params.row.reconciliationStatus,
				valueFormatter: (params) => normalizeReconciliationStatus(params.value),
				renderCell: (params) => {
					return (
						<Tooltip
							title={
								params.value ===
								normalizeReconciliationStatus(ReconciliationStatus.Posted)
									? 'The cleared transaction has no associated records.'
									: 'Has associated records.'
							}
						>
							<Typography
								onClick={() => {
									if (params.row.reconciliationRecordId) {
										viewReconciliation(params.row.reconciliationRecordId);
									}
								}}
								sx={(theme) => ({
									textDecoration: params.row.reconciliationRecordId
										? 'underline'
										: undefined,
									color: params.row.reconciliationRecordId
										? theme.palette.denim[500]
										: undefined,
									lineHeight: 1.43,
									letterSpacing: '0.01071em',
									fontSize: '0.875rem',
									cursor: params.row.reconciliationRecordId
										? 'pointer'
										: undefined,
								})}
							>
								{normalizeReconciliationStatus(params.value)}
							</Typography>
						</Tooltip>
					);
				},
			},
		);
	}, [
		currencyOption.id,
		handleReportedTransactionDeleteClick,
		handleReportedTransactionReconcileClick,
		handleTransactionViewClick,
		user.cash4.isAuthor,
		viewReconciliation,
	]);

	const reportedTransactionColumnsWithVisibility = () => {
		var vis = reportedTransactionsColumnVisibility as GridColumnVisibilityModel;

		reportedTransactionColumns.forEach((x) => {
			if (vis[x.field] !== undefined && vis[x.field] === false) {
				vis[x.field] = false;
			} else {
				vis[x.field] = true;
			}
		});

		return vis;
	};

	const [
		reportedTransactionColumnVisibilityModel,
		setTransactionColumnVisibilityModel,
	] = useState<GridColumnVisibilityModel>(
		reportedTransactionColumnsWithVisibility(),
	);

	const projectedTransactionsColumns = useMemo<GridColDef[]>(() => {
		return getProjectedTransactionsColumnDefs(
			{
				...getCurrencyColumnDefinition(),
				field: 'amount',
				headerName: 'Amount',
				valueGetter: (params) => params.row.amount.accountCurrencyAmount,
				renderCell: (params) => {
					return AmountCurrencyTooltipGridCell({
						accountCurrencyAmount: params.row.amount.accountCurrencyAmount,
						accountCurrencyCode: params.row.amount.accountCurrencyCode,
						reportingCurrencyAmount:
							params.row.amount.reportingCurrencyAmount || null,
						reportingCurrencyCode:
							params.row.amount.reportingCurrencyCode || defaultCurrencyCode,
						displayedCurrencyCode:
							currencyOption.id === 'reporting'
								? CurrencySource.Reporting
								: CurrencySource.Account,
					});
				},
			},
			{
				field: 'currencyCode',
				headerName: 'Currency Code',
				valueGetter: (params) => {
					return currencyOption.id === 'reporting'
						? params.row.amount.reportingCurrencyCode
						: params.row.amount.accountCurrencyCode;
				},
			},
			{
				field: 'actions',
				headerName: '',
				width: 25,
				renderCell: (params) => {
					return (
						<Actions
							objecttype="Transaction"
							stonlyId={stonly.projectedTransactionsRowContextMenu}
							actions={[
								{
									action: ActionsEnum.view,
									callback: () => handleProjectedViewClick(params.row),
								},
								...(user.cash4.isAuthor
									? [
											{
												action: ActionsEnum.edit,
												callback: () => handleProjectedEditClick(params.row),
											},
									  ]
									: []),
								...(user.cash4.isAuthor
									? [
											{
												action: ActionsEnum.delete,
												isDisabled: params.row.reconciliationId
													? true
													: undefined,
												isDisabledTooltipText:
													'This record is associated with a reconciliation and cannot be deleted. Edit reconciliation to remove association.',
												callback: () =>
													handleProjectedTransactionDeleteClick(params.row),
											},
									  ]
									: []),
								...(user.cash4.isAuthor && !params.row.reconciliationId
									? [
											{
												action: ActionsEnum.reconcile,
												isDisabledTooltipText:
													'This record is already associated with a reconciliation. Edit reconciliation to remove association.',
												callback: () =>
													handleProjectedTransactionReconcileClick(params.row),
											},
									  ]
									: []),
							]}
							id="projectedTransactionId-more-menu"
						/>
					);
				},
				resizable: false,
				sortable: false,
				disableColumnMenu: true,
				filterable: false,
				disableExport: true,
				hideable: false,
				disableReorder: true,
			},
			{
				field: 'reconciliationStatus',
				headerName: 'Reconciliation Status',
				valueGetter: (params) => params.row.reconciliationStatus,
				valueFormatter: (params) => normalizeReconciliationStatus(params.value),
				renderCell: (params) => {
					return (
						<Tooltip
							title={
								params.value === 'Unreconciled'
									? 'Perform reconciliation to associate records.'
									: 'Has associated records.'
							}
						>
							<Typography
								onClick={() => {
									if (params.row.reconciliationId) {
										viewReconciliation(params.row.reconciliationId);
									}
								}}
								sx={(theme) => ({
									textDecoration: params.row.reconciliationId
										? 'underline'
										: undefined,
									color: params.row.reconciliationId
										? theme.palette.denim[500]
										: undefined,
									lineHeight: 1.43,
									letterSpacing: '0.01071em',
									fontSize: '0.875rem',
									cursor: params.row.reconciliationId ? 'pointer' : undefined,
								})}
							>
								{normalizeReconciliationStatus(params.value)}
							</Typography>
						</Tooltip>
					);
				},
			},
		);
	}, [
		user.cash4.isAuthor,
		handleProjectedViewClick,
		handleProjectedEditClick,
		handleProjectedTransactionDeleteClick,
		handleProjectedTransactionReconcileClick,
		viewReconciliation,
		currencyOption.id,
	]);

	const projectedTransactionColumnsWithVisibility = useMemo(() => {
		var vis =
			projectedTransactionsColumnVisibility as GridColumnVisibilityModel;
		projectedTransactionsColumns.forEach((x) => {
			if (vis[x.field] !== undefined && vis[x.field] === false) {
				vis[x.field] = false;
			} else {
				vis[x.field] = true;
			}
		});

		return vis;
	}, [projectedTransactionsColumns]);

	const [
		projectedTransactionColumnVisibilityModel,
		setProjectedTransactionColumnVisibilityModel,
	] = useState<GridColumnVisibilityModel>(
		projectedTransactionColumnsWithVisibility,
	);

	const customNoProjectedTransactionsOverlay = useMemo(
		() => () => (
			<NoRowsOverlay
				icon={Balance}
				heading={
					<Typography variant="h3">No Projected Transactions</Typography>
				}
				body={
					user.cash4.isAuthor ? (
						<Typography variant="body1">
							Select{' '}
							<Link
								underline="always"
								color={brandColors.denim[500]}
								sx={{ cursor: 'pointer' }}
								onClick={() => setIsProjectedCreatedEditDrawerOpen(true)}
							>
								Create Projection
							</Link>{' '}
							to add your data, or adjust the date range to view additional
							transactions.
						</Typography>
					) : (
						<Typography variant="body1">
							Adjust your date range to view projected transactions or contact
							your administrator to add data.
						</Typography>
					)
				}
			/>
		),
		[user.cash4.isAuthor],
	);

	const forecastedTransactionsColumns = useMemo<GridColDef[]>(() => {
		return getForecastedTransactionsColumnDefs(
			{
				...getCurrencyColumnDefinition(),
				field: 'amount',
				headerName: 'Amount',
				valueGetter: (params) => params.row.amount.accountCurrencyAmount,
				renderCell: (params) => {
					return AmountCurrencyTooltipGridCell({
						accountCurrencyAmount: params.row.amount.accountCurrencyAmount,
						accountCurrencyCode: params.row.amount.accountCurrencyCode,
						reportingCurrencyAmount:
							params.row.amount.reportingCurrencyAmount || null,
						reportingCurrencyCode:
							params.row.amount.reportingCurrencyCode || defaultCurrencyCode,
						displayedCurrencyCode:
							currencyOption.id === 'reporting'
								? CurrencySource.Reporting
								: CurrencySource.Account,
					});
				},
			},
			{
				field: 'currencyCode',
				headerName: 'Currency Code',
				valueGetter: (params) => {
					return currencyOption.id === 'reporting'
						? params.row.amount.reportingCurrencyCode
						: params.row.amount.accountCurrencyCode;
				},
			},
			{
				field: 'actions',
				headerName: '',
				width: 25,
				renderCell: (params) => {
					return (
						<Actions
							objecttype="Forecasted Transaction"
							stonlyId={stonly.forecastedTransactionsRowContextMenu}
							actions={[
								// 	{
								// 		action: ActionsEnum.view,
								// 		callback: () => handleProjectedViewClick(params.row),
								// 	},
								// 	...(user.cash4.isAuthor
								// 		? [
								// 				{
								// 					action: ActionsEnum.edit,
								// 					callback: () => handleProjectedEditClick(params.row),
								// 				},
								// 		  ]
								// 		: []),
								...(user.cash4.isAuthor
									? [
											{
												action: ActionsEnum.delete,
												isDisabled: params.row.reconciliationSummary
													?.reconciliationRecordId
													? true
													: undefined,
												isDisabledTooltipText:
													'This record is associated with a reconciliation and cannot be deleted. Edit reconciliation to remove association.',
												callback: () =>
													handleForecastedTransactionDeleteClick(params.row),
											},
									  ]
									: []),
								// 	...(user.cash4.isAuthor && !params.row.reconciliationId
								// 		? [
								// 				{
								// 					action: ActionsEnum.reconcile,
								// 					isDisabledTooltipText:
								// 						'This record is already associated with a reconciliation. Edit reconciliation to remove association.',
								// 					callback: () =>
								// 						handleProjectedTransactionReconcileClick(params.row),
								// 				},
								// 		  ]
								// 		: []),
							]}
							id="forecasted-transactions-context-menu"
						/>
					);
				},
				resizable: false,
				sortable: false,
				disableColumnMenu: true,
				filterable: false,
				disableExport: true,
				hideable: false,
				disableReorder: true,
			},
			{
				field: '_reconciliation_status',
				headerName: 'Reconciliation Status',
				valueGetter: (params) => params.row.reconciliationStatus,
				valueFormatter: (params) => normalizeReconciliationStatus(params.value),
				renderCell: (params) => {
					return (
						<Tooltip
							title={
								params.value === 'Unreconciled'
									? 'Perform reconciliation to associate records.'
									: 'Has associated records.'
							}
						>
							<Typography
								onClick={() => {
									if (
										params.row.reconciliationSummary?.reconciliationRecordId
									) {
										viewReconciliation(
											params.row.reconciliationSummary?.reconciliationRecordId,
										);
									}
								}}
								sx={(theme) => ({
									textDecoration: params.row.reconciliationSummary
										?.reconciliationRecordId
										? 'underline'
										: undefined,
									color: params.row.reconciliationSummary
										?.reconciliationRecordId
										? theme.palette.denim[500]
										: undefined,
									lineHeight: 1.43,
									letterSpacing: '0.01071em',
									fontSize: '0.875rem',
									cursor: params.row.reconciliationSummary
										?.reconciliationRecordId
										? 'pointer'
										: undefined,
								})}
							>
								{normalizeReconciliationStatus(params.value)}
							</Typography>
						</Tooltip>
					);
				},
			},
		);
	}, [
		user.cash4.isAuthor,
		//handleForecastedViewClick,
		//handleForecastedEditClick,
		handleForecastedTransactionDeleteClick,
		//handleForecastedTransactionReconcileClick,
		viewReconciliation,
		currencyOption.id,
	]);

	const customNoForecastedTransactionsOverlay = useMemo(
		() => () => (
			<NoRowsOverlay
				icon={Balance}
				heading={
					<Typography variant="h3">No Forecasted Transactions</Typography>
				}
				body={
					user.cash4.isAuthor ? (
						<Typography variant="body1">
							Select{' '}
							<Link
								underline="always"
								color={brandColors.denim[500]}
								sx={{ cursor: 'pointer' }}
								onClick={() => setIsProjectedCreatedEditDrawerOpen(true)}
							>
								Create Forecasted Transaction
							</Link>{' '}
							to add your data, or adjust the date range to view additional
							transactions.
						</Typography>
					) : (
						<Typography variant="body1">
							Adjust your date range to view forecasted transactions or contact
							your administrator to add data.
						</Typography>
					)
				}
			/>
		),
		[user.cash4.isAuthor],
	);

	const reconcileButton = useMemo(
		() => (
			<T4Button
				variant="outlined"
				startIcon={<SwapHoriz />}
				onClick={() => {
					startReconciliationCreation();
				}}
			>
				Reconcile
			</T4Button>
		),
		[startReconciliationCreation],
	);

	const updateCurrentDayButton = useMemo(
		() => (
			<T4Button
				variant="outlined"
				startIcon={<CurrencyExchange />}
				onClick={() => {
					updateCurrentDay();
				}}
			>
				Update
			</T4Button>
		),
		[updateCurrentDay],
	);

	const reportedSelection = useRowSelectionCalculations(
		data?.map((x) => ({
			id: x.id,
			accountAmount: x.number.accountCurrencyAmount,
			accountCurrencyCode: x.currency,
			reportingAmount: x.number.reportingCurrencyAmount,
			reportingCurrencyCode:
				configurations?.reportingCurrencyCode || defaultCurrencyCode,
		})) ?? [],
	);

	const projectedSelection = useRowSelectionCalculations(
		projectedTransactions?.map((pt: ProjectedTransaction) => ({
			id: pt.id,
			accountAmount: pt.amount.accountCurrencyAmount,
			accountCurrencyCode: pt.amount.accountCurrencyCode,
			reportingAmount: pt.amount.reportingCurrencyAmount,
			reportingCurrencyCode:
				pt.amount.reportingCurrencyCode || defaultCurrencyCode,
		})) ?? [],
	);

	const forecastedSelection = useRowSelectionCalculations(
		forecastedTransactions?.map((t: ForecastedTransaction) => ({
			id: t.id,
			accountAmount: t.amount.accountCurrencyAmount,
			accountCurrencyCode: t.amount.accountCurrencyCode,
			reportingAmount: t.amount.reportingCurrencyAmount,
			reportingCurrencyCode:
				t.amount.reportingCurrencyCode || defaultCurrencyCode,
		})) ?? [],
	);

	const reportedTransactionColumnVisibilityChange = (
		columnVisibilityModel: GridColumnVisibilityModel,
	) => {
		setTransactionColumnVisibilityModel(columnVisibilityModel);
	};

	const reportedTransactionColsToExport = useMemo(() => {
		let cols = [] as string[];
		Object.keys(reportedTransactionColumnVisibilityModel).forEach((colName) => {
			const columnDefinition = reportedTransactionColumns.find(
				(x) => x.field === colName,
			);
			if (
				reportedTransactionColumnVisibilityModel[colName] === true &&
				!columnDefinition?.disableExport
			) {
				cols.push(colName);
			} else {
			}
		});

		const amountIndex = cols?.indexOf('currency');
		if (cols && amountIndex !== -1) {
			const newCols = [
				...cols.slice(0, amountIndex + 1),
				'reportingCurrency',
				...cols.slice(amountIndex + 1),
			];
			cols = newCols;
		}
		const numberIndex = cols?.indexOf('number');
		if (cols && numberIndex !== -1) {
			const newCols = [
				...cols.slice(0, numberIndex + 1),
				'reportingNumber',
				...cols.slice(numberIndex + 1),
			];

			cols = newCols;
		}
		return cols;
	}, [reportedTransactionColumns, reportedTransactionColumnVisibilityModel]);

	const projectedTransactionColumnVisibilityChange = (
		columnVisibilityModel: GridColumnVisibilityModel,
	) => {
		setProjectedTransactionColumnVisibilityModel(columnVisibilityModel);
	};

	const projectedTransactionColsToExport = useMemo(() => {
		let cols = [] as string[];
		Object.keys(projectedTransactionColumnVisibilityModel).forEach(
			(colName) => {
				const columnDefinition = projectedTransactionsColumns.find(
					(x) => x.field === colName,
				);
				if (
					projectedTransactionColumnVisibilityModel[colName] === true &&
					!columnDefinition?.disableExport
				) {
					cols.push(colName);
				}
			},
		);

		const amountIndex = cols?.indexOf('currencyCode');
		if (cols && amountIndex !== -1) {
			const newCols = [
				...cols.slice(0, amountIndex + 1),
				'reportingCurrency',
				...cols.slice(amountIndex + 1),
			];
			cols = newCols;
		}
		const numberIndex = cols?.indexOf('amount');
		if (cols && numberIndex !== -1) {
			const newCols = [
				...cols.slice(0, numberIndex + 1),
				'reportingAmount',
				...cols.slice(numberIndex + 1),
			];

			cols = newCols;
		}
		return cols;
	}, [projectedTransactionColumnVisibilityModel, projectedTransactionsColumns]);

	const reportedGrid = useMemo(
		() => (
			<UserPreferencesDataGrid
				tableKey="transactionsPage"
				columns={reportedTransactionColumns}
				stonlyId="transactions"
				rows={data}
				loading={isFetching}
				calculateColumnWidths
				getRowClassName={(
					params: GridRowClassNameParams<TransactionListItem>,
				) => (!params.row.cfc ? 'uncategorized-row' : '')}
				initialState={{
					sorting: {
						sortModel: [
							{ field: 'date', sort: 'desc' },
							{ field: 'e4AccountNumber', sort: 'asc' },
							{ field: 'transactionCode', sort: 'asc' },
						],
					},
				}}
				pinnedColumns={{ left: ['actions'] }}
				showToolbar
				showCustomViewButton
				pagination
				hideFooter={false}
				autoPageSize
				rowSelectionModel={reportedSelection.selectedRowIds}
				onRowSelectionModelChange={reportedSelection.setRowSelectionHandler}
				columnVisibilityModel={reportedTransactionColumnVisibilityModel}
				onColumnVisibilityModelChange={
					reportedTransactionColumnVisibilityChange
				}
				csvOptions={{ fields: reportedTransactionColsToExport }}
			/>
		),
		[
			reportedTransactionColumns,
			data,
			isFetching,
			reportedSelection.selectedRowIds,
			reportedSelection.setRowSelectionHandler,
			reportedTransactionColumnVisibilityModel,
			reportedTransactionColsToExport,
		],
	);

	const projectedGrid = useMemo(
		() => (
			<UserPreferencesDataGrid
				stonlyId={stonly.projectedTransactionsGrid}
				tableKey="projectedTransactionsPage"
				loading={isProjectedTransactionsFetching}
				columns={projectedTransactionsColumns}
				rows={projectedTransactions}
				calculateColumnWidths
				initialState={{
					sorting: {
						sortModel: [
							{
								field: 'expectedValueDate',
								sort: 'asc',
							},
						],
					},
				}}
				pinnedColumns={{ left: ['actions'] }}
				showToolbar
				showCustomViewButton
				pagination
				hideFooter={false}
				autoPageSize
				slots={{ noRowsOverlay: customNoProjectedTransactionsOverlay }}
				rowSelectionModel={projectedSelection.selectedRowIds}
				onRowSelectionModelChange={projectedSelection.setRowSelectionHandler}
				columnVisibilityModel={projectedTransactionColumnVisibilityModel}
				onColumnVisibilityModelChange={
					projectedTransactionColumnVisibilityChange
				}
				csvOptions={{ fields: projectedTransactionColsToExport }}
			/>
		),
		[
			isProjectedTransactionsFetching,
			projectedTransactionsColumns,
			projectedTransactions,
			customNoProjectedTransactionsOverlay,
			projectedSelection.selectedRowIds,
			projectedSelection.setRowSelectionHandler,
			projectedTransactionColumnVisibilityModel,
			projectedTransactionColsToExport,
		],
	);

	const forecastedGrid = useMemo(
		() => (
			<UserPreferencesDataGrid
				stonlyId={stonly.forecastedTransactionsGrid}
				tableKey="forecastedTransactionsPage"
				loading={isForecastedTransactionsFetching}
				columns={forecastedTransactionsColumns}
				columnVisibilityModel={forecastedTransactionsColumnVisibility}
				rows={forecastedTransactions}
				calculateColumnWidths
				initialState={{
					sorting: {
						sortModel: [
							{
								field: 'expectedValueDate',
								sort: 'asc',
							},
							{
								field: 'dueDate',
								sort: 'asc',
							},
						],
					},
				}}
				pinnedColumns={{ left: ['actions'] }}
				showToolbar
				showCustomViewButton
				pagination
				hideFooter={false}
				autoPageSize
				slots={{ noRowsOverlay: customNoForecastedTransactionsOverlay }}
				rowSelectionModel={forecastedSelection.selectedRowIds}
				onRowSelectionModelChange={forecastedSelection.setRowSelectionHandler}
			/>
		),
		[
			isForecastedTransactionsFetching,
			forecastedTransactionsColumns,
			forecastedTransactions,
			customNoForecastedTransactionsOverlay,
			forecastedSelection.selectedRowIds,
			forecastedSelection.setRowSelectionHandler,
		],
	);

	const getTransactionActions = useMemo((): Action[] | undefined => {
		if (user.cash4.isAuthor) {
			const actions: Action[] = [
				{
					action: ActionsEnum.delete,
					isDisabled: transaction?.reconciliationRecordId ? true : undefined,
					isDisabledTooltipText:
						'This record is associated with a reconciliation and cannot be deleted. Edit reconciliation to remove association.',
					callback: () => handleReportedTransactionDeleteClick(transaction!),
				},
			];

			if (!transaction?.reconciliationRecordId) {
				actions.push({
					action: ActionsEnum.reconcile,
					callback: () => handleReportedTransactionReconcileClick(transaction!),
				});
			}
			return actions;
		} else {
			return undefined;
		}
	}, [
		handleReportedTransactionDeleteClick,
		handleReportedTransactionReconcileClick,
		transaction,
		user.cash4.isAuthor,
	]);

	const getProjectedTransactionActions = useMemo((): Action[] | undefined => {
		if (user.cash4.isAuthor) {
			const actions: Action[] = [
				{
					action: ActionsEnum.delete,
					isDisabled: projectedTransaction?.reconciliationId ? true : undefined,
					isDisabledTooltipText:
						'This record is associated with a reconciliation and cannot be deleted. Edit reconciliation to remove association.',
					callback: () =>
						handleProjectedTransactionDeleteClick(projectedTransaction!),
				},
				{
					action: ActionsEnum.edit,
					callback: () => handleProjectedEditClick(projectedTransaction!),
				},
			];
			if (!projectedTransaction?.reconciliationId) {
				actions.push({
					action: ActionsEnum.reconcile,
					callback: () =>
						handleProjectedTransactionReconcileClick(projectedTransaction!),
				});
			}
			return actions;
		} else {
			return undefined;
		}
	}, [
		user.cash4.isAuthor,
		projectedTransaction,
		handleProjectedTransactionDeleteClick,
		handleProjectedEditClick,
		handleProjectedTransactionReconcileClick,
	]);

	useEffect(() => {
		if (!tabValue) {
			history.push(history.location.pathname + `?tab=${'reported'}`);
		}
	}, [history, tabValue]);

	//do not add reportedSelection as a dependency
	useEffect(() => {
		if (isFetching) {
			reportedSelection.setRowSelectionHandler([]);
		}
	}, [isFetching]);

	//do not add projectedSelection as a dependency
	useEffect(() => {
		if (isProjectedTransactionsFetching) {
			projectedSelection.setRowSelectionHandler([]);
		}
	}, [isProjectedTransactionsFetching]);

	//do not add forecastedSelection as a dependency
	useEffect(() => {
		if (isForecastedTransactionsFetching) {
			forecastedSelection.setRowSelectionHandler([]);
		}
	}, [isForecastedTransactionsFetching]);

	return (
		<T4View
			header={
				<PageHeader
					id={pageHeaderStonlyIds.transactionsPage}
					title="Transactions"
				/>
			}
		>
			<TabContext value={tabValue}>
				<Grid
					container
					wrap="nowrap"
					sx={{
						gap: 2,
						flexDirection: 'column',
						height: '100%',
						width: '100%',
					}}
				>
					<Grid container item xs="auto">
						<TabList
							onChange={(_, tab) => {
								const setTab = (tab: TransactionPageTab) => {
									history.push(history.location.pathname + `?tab=${tab}`);
								};

								if (tab === 'reported') {
									setTab(tab);
								} else if (tab === 'projected') {
									setTab(tab);
								} else if (tab === 'forecasted') {
									setTab(tab);
								} else {
									setTab('reported');
								}
							}}
						>
							<Tab label="Reported" value="reported" />
							<Tab label="Projected" value="projected" />
							{forecastedTransactionsEnabled && (
								<Tab label="Forecasted" value="forecasted" />
							)}
						</TabList>
					</Grid>
					<Grid item xs={true}>
						<TabPanel value="reported" sx={{ height: '100%', padding: 0 }}>
							<Grid
								item
								container
								direction="column"
								sx={{
									gap: 2,
									height: '100%',
									flexWrap: 'nowrap',
								}}
							>
								<Grid container item spacing={2}>
									<Grid item container sm={12} md="auto">
										<T4DateRangePicker
											defaultValue={dateRange}
											onAccept={([startDate, endDate]) => {
												if (
													startDate &&
													startDate.isValid() &&
													endDate &&
													endDate.isValid() &&
													startDate.isSameOrBefore(endDate)
												) {
													if (
														!startDate.isSame(dateRange[0]) ||
														!endDate.isSame(dateRange[1])
													) {
														handleDateRangeChange([startDate, endDate]);
													}
												}
											}}
											maxDate={moment()}
											disableFuture
											showShortcuts
											shortcutResetDefaults={defaultDateRange}
											sx={{ marginBottom: 0 }}
										/>
									</Grid>
									<Grid item container sm={12} md="auto">
										<T4TextFieldV2
											label="Display Currency"
											select
											value={currencyOption.id}
											onChange={(event) => {
												handleCurrencyOptionChange(
													event as CurrencyOption['id'],
												);
											}}
										>
											{[
												{ id: 'original', value: 'Account Currency' },
												{
													id: 'reporting',
													value: `Reporting Currency (${
														configurations?.reportingCurrencyCode ||
														defaultCurrencyCode
													})`,
												},
											].map((x) => {
												return (
													<MenuItem key={x.id} value={x.id}>
														{x.value}
													</MenuItem>
												);
											})}
										</T4TextFieldV2>
									</Grid>
									<Grid item container sm={12} md="auto">
										<LegalEntityGroupsFilter loading={isFetching} />
									</Grid>
									<Grid
										item
										container
										sm={12}
										lg="auto"
										spacing={2}
										sx={{
											alignContent: 'flex-end',
											'@media (min-width: 1640px)': {
												marginLeft: 'auto',
											},
										}}
									>
										{user.cash4.isAuthor && currentDayRefreshStatus && (
											<Tooltip title={lastRefreshedDate}>
												<Grid item container sm={6} md="auto">
													{updateCurrentDayButton}
												</Grid>
											</Tooltip>
										)}
										{user.cash4.isAuthor && (
											<Grid item container sm={6} md="auto">
												{createTransactionButton}
											</Grid>
										)}
										{user.cash4.isAuthor && (
											<Grid item container sm={6} md="auto">
												{reconcileButton}
											</Grid>
										)}
									</Grid>
								</Grid>
								{error && (
									<Grid item xs="auto">
										<T4AlertStack error={error as string} />
									</Grid>
								)}
								<Grid item xs={true}>
									<ActuallyPrettyGoodDataGridWrapper
										sx={{
											height: '100%',
											'& .uncategorized-row': {
												backgroundColor: '#FFFDE3',
											},
											'& .MuiDataGrid-columnHeaders': {
												height: '3rem !important',
												maxHeight: '3rem !important',
											},
											'& .MuiDataGrid-columnHeader': {
												height: '3rem !important',
												maxHeight: '3rem !important',
											},
											'& .MuiDataGrid-columnHeadersInner': {
												height: '3rem !important',
												maxHeight: '3rem !important',
											},
											'& .MuiDataGrid-columnHeaderTitle': {
												height: '3rem !important',
												maxHeight: '3rem !important',
												display: 'flex',
												flexWrap: 'wrap',
												whiteSpace: 'break-spaces',
												lineHeight: '1.5rem',
												alignContent: 'center',
											},
										}}
									>
										{reportedGrid}
									</ActuallyPrettyGoodDataGridWrapper>
								</Grid>
								{reportedSelection.isSelectedTotalValid &&
									reportedSelection.selectedCount >= 2 && (
										<Grid item xs="auto">
											<T4Alert severity="info" fullWidth sx={{ width: '100%' }}>
												{'The '}
												<C4AlertBold>{`${
													reportedSelection.selectedCurrencySource ===
													CurrencySource.Account
														? 'account currency'
														: 'reporting currency'
												}`}</C4AlertBold>
												{' total of the selected rows is '}
												<C4AlertBold>{`${formatCurrency(
													reportedSelection.selectedTotal,
													{
														currency: reportedSelection.selectedCurrency,
													},
												)} ${reportedSelection.selectedCurrency}`}</C4AlertBold>
												{'.'}
											</T4Alert>
										</Grid>
									)}
								{!reportedSelection.isSelectedTotalValid && (
									<Grid item xs="auto">
										<T4Alert severity="info" fullWidth sx={{ width: '100%' }}>
											{'The '}
											<C4AlertBold>{`${
												reportedSelection.selectedCurrencySource ===
												CurrencySource.Account
													? 'account currency'
													: 'reporting currency'
											}`}</C4AlertBold>
											{
												' total of the selected rows is unavailable due to missing exchange rates. Select records with the same account currency.'
											}
										</T4Alert>
									</Grid>
								)}
							</Grid>
						</TabPanel>

						<TabPanel value="projected" sx={{ height: '100%', padding: 0 }}>
							<Grid
								item
								container
								direction="column"
								sx={{
									gap: 2,
									height: '100%',
									flexWrap: 'nowrap',
								}}
							>
								<Grid container item spacing={2}>
									<Grid item container sm={12} md="auto">
										<T4DateRangePicker
											defaultValue={projectedTransactionDateRange}
											onAccept={([startDate, endDate]) => {
												if (
													startDate &&
													startDate.isValid() &&
													endDate &&
													endDate.isValid() &&
													startDate.isSameOrBefore(endDate)
												) {
													if (
														!startDate.isSame(dateRange[0]) ||
														!endDate.isSame(dateRange[1])
													) {
														handleProjectedTransactionDateRangeChange([
															startDate,
															endDate,
														]);
													}
												}
											}}
											showShortcuts
											sx={{ marginBottom: 0 }}
										/>
									</Grid>
									<Grid item container sm={12} md="auto">
										<LegalEntityGroupsFilter
											loading={isProjectedTransactionsFetching}
										/>
									</Grid>
									<Grid
										item
										container
										sm={12}
										lg="auto"
										spacing={2}
										sx={{
											alignContent: 'flex-end',
											'@media (min-width: 1330px)': {
												marginLeft: 'auto',
											},
										}}
									>
										{user.cash4.isAuthor && (
											<Grid item container sm={6} md="auto">
												{createTransactionButton}
											</Grid>
										)}
										{user.cash4.isAuthor && (
											<Grid item container sm={6} md="auto">
												{reconcileButton}
											</Grid>
										)}
									</Grid>
								</Grid>
								<Grid item xs={true}>
									<ActuallyPrettyGoodDataGridWrapper>
										{projectedGrid}
									</ActuallyPrettyGoodDataGridWrapper>
								</Grid>
								{projectedSelection.isSelectedTotalValid &&
									projectedSelection.selectedCount >= 2 && (
										<Grid item xs="auto">
											<T4Alert severity="info" fullWidth sx={{ width: '100%' }}>
												{'The '}
												<C4AlertBold>{`${
													projectedSelection.selectedCurrencySource ===
													CurrencySource.Account
														? 'account currency'
														: 'reporting currency'
												}`}</C4AlertBold>
												{' total of the selected rows is '}
												<C4AlertBold>{`${formatCurrency(
													projectedSelection.selectedTotal,
													{
														currency: projectedSelection.selectedCurrency,
													},
												)} ${
													projectedSelection.selectedCurrency
												}`}</C4AlertBold>
												{'.'}
											</T4Alert>
										</Grid>
									)}
								{!projectedSelection.isSelectedTotalValid && (
									<Grid item xs="auto">
										<T4Alert severity="info" fullWidth sx={{ width: '100%' }}>
											{'The '}
											<C4AlertBold>{`${
												projectedSelection.selectedCurrencySource ===
												CurrencySource.Account
													? 'account currency'
													: 'reporting currency'
											}`}</C4AlertBold>
											{
												' total of the selected rows is unavailable due to missing exchange rates. Select records with the same account currency.'
											}
										</T4Alert>
									</Grid>
								)}
							</Grid>
						</TabPanel>

						<TabPanel value="forecasted" sx={{ height: '100%', padding: 0 }}>
							<Grid
								item
								container
								direction="column"
								sx={{
									gap: 2,
									height: '100%',
									flexWrap: 'nowrap',
								}}
							>
								<Grid container item spacing={2}>
									<Grid item container sm={12} md="auto">
										<T4DateRangePicker
											defaultValue={forecastedTransactionDateRange}
											onAccept={([startDate, endDate]) => {
												if (
													startDate &&
													startDate.isValid() &&
													endDate &&
													endDate.isValid() &&
													startDate.isSameOrBefore(endDate)
												) {
													if (
														!startDate.isSame(dateRange[0]) ||
														!endDate.isSame(dateRange[1])
													) {
														handleForecastedTransactionDateRangeChange([
															startDate,
															endDate,
														]);
													}
												}
											}}
											showShortcuts
											sx={{ marginBottom: 0 }}
										/>
									</Grid>
									<Grid item container sm={12} md="auto">
										<LegalEntityGroupsFilter
											loading={isForecastedTransactionsFetching}
										/>
									</Grid>
									<Grid
										item
										container
										sm={12}
										lg="auto"
										spacing={2}
										sx={{
											alignContent: 'flex-end',
											'@media (min-width: 1330px)': {
												marginLeft: 'auto',
											},
										}}
									>
										{user.cash4.isAuthor && (
											<Grid item container sm={6} md="auto">
												{createTransactionButton}
											</Grid>
										)}
										{user.cash4.isAuthor && (
											<Grid item container sm={6} md="auto">
												{reconcileButton}
											</Grid>
										)}
									</Grid>
								</Grid>
								<Grid item xs={true}>
									<ActuallyPrettyGoodDataGridWrapper>
										{forecastedGrid}
									</ActuallyPrettyGoodDataGridWrapper>
								</Grid>
								{forecastedSelection.isSelectedTotalValid &&
									forecastedSelection.selectedCount >= 2 && (
										<Grid item xs="auto">
											<T4Alert severity="info" fullWidth sx={{ width: '100%' }}>
												{'The '}
												<C4AlertBold>{`${
													forecastedSelection.selectedCurrencySource ===
													CurrencySource.Account
														? 'account currency'
														: 'reporting currency'
												}`}</C4AlertBold>
												{' total of the selected rows is '}
												<C4AlertBold>{`${formatCurrency(
													forecastedSelection.selectedTotal,
													{
														currency: forecastedSelection.selectedCurrency,
													},
												)} ${
													forecastedSelection.selectedCurrency
												}`}</C4AlertBold>
												{'.'}
											</T4Alert>
										</Grid>
									)}
								{!forecastedSelection.isSelectedTotalValid && (
									<Grid item xs="auto">
										<T4Alert severity="info" fullWidth sx={{ width: '100%' }}>
											{'The '}
											<C4AlertBold>{`${
												forecastedSelection.selectedCurrencySource ===
												CurrencySource.Account
													? 'account currency'
													: 'reporting currency'
											}`}</C4AlertBold>
											{
												' total of the selected rows is unavailable due to missing exchange rates. Select records with the same account currency.'
											}
										</T4Alert>
									</Grid>
								)}
							</Grid>
						</TabPanel>
					</Grid>
				</Grid>
			</TabContext>

			<C4TransactionsDrawer
				isOpen={openTransactionDrawer}
				onClose={handleCloseTransactionDrawer}
				transactionId={transactionId}
				disableLink={false}
				transactionActions={getTransactionActions}
			/>

			<T4Drawer
				open={openProjectionDrawer}
				onClose={handleCloseProjectionDrawer}
				stonlyPrefix="projected-transaction-details"
			>
				<ProjectionViewDrawer
					projectionId={projectionId}
					projectedTransactionActions={getProjectedTransactionActions}
				/>
			</T4Drawer>

			{reconciliationDrawer}

			<C4ProjectedEditModal
				isOpen={isEditWarningOpen}
				onCancel={() => {
					if (!openProjectionDrawer) {
						setProjectedTransaction(undefined);
					}
					setIsProjectedCreatedEditDrawerOpen(false);
					setIsEditWarningOpen(false);
				}}
				onConfirm={() => {
					setOpenProjectionDrawer(false);
					setIsProjectedCreatedEditDrawerOpen(true);
					setIsEditWarningOpen(false);
				}}
			/>

			<ConfirmationDialog
				open={openDialog}
				onClose={handleCloseDialog}
				title={selectedDialog.title || ''}
				text={selectedDialog.text}
				primaryButtonText={selectedDialog.primaryButtonText}
				secondaryButtonText={selectedDialog.secondaryButtonText}
				onPrimaryButtonClick={selectedDialog.onPrimaryButtonClick}
				onSecondaryButtonClick={selectedDialog.onSecondaryButtonClick}
				stonlyIds={{
					confirmationModal: stonly.transactionsDeleteConfirmationModal,
					secondaryButton: stonly.transactionsDeleteConfirmationCancelButton,
					primaryButton: stonly.transactionsDeleteConfirmationDeleteButton,
				}}
				loading={confirmationModalLoading}
			/>
			{ProjectedCreateEditDrawer}
			{ForecastedCreateEditDrawer}
		</T4View>
	);
});
