import { East } from '@mui/icons-material';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { rem } from '../../utilities/styles';

export type SolutionsCardProps = {
	name: string;
	isDisabled: boolean;
	urlPath: string;
	text?: string;
	image?: string;
	previewUrl?: string;
};

const SolutionCard: FC<SolutionsCardProps> = observer((props) => {
	const theme = useTheme();
	const history = useHistory();

	const goToModuleDashboard = () => {
		if (props.urlPath && !props.isDisabled) {
			history.push(props.urlPath);
		}
	};

	const buttonContent = useMemo(
		() => (
			<Box
				sx={{
					height: '100%',
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					textAlign: 'center',
				}}
			>
				<Box>
					<Box
						component="img"
						src={props.image}
						alt={props.name}
						sx={{
							height: rem(50),
							width: '100%',
							paddingX: '1rem',
							[theme.breakpoints.down('sm')]: {
								height: rem(40),
							},
						}}
					/>
					<Typography
						sx={{
							color: '#FFFFFF',
						}}
					>
						{props.text}
					</Typography>
				</Box>
				{props.previewUrl && (
					<Button
						target="_blank"
						href={props.previewUrl}
						color="primary"
						variant="contained"
						endIcon={<East />}
						sx={{
							marginTop: '1.25rem',
							width: 'fit-content',
						}}
					>
						Check It Out
					</Button>
				)}
			</Box>
		),
		[props, theme.breakpoints],
	);

	return props.isDisabled ? (
		<Box
			sx={{
				width: '100%',
				height: rem(200),
				backgroundColor: '#404041BF',
				borderRadius: rem(12),
				appearance: 'none',
				border: 0,
				textTransform: 'uppercase',
				[theme.breakpoints.down('sm')]: {
					height: rem(150),
				},
				cursor: 'default',
			}}
		>
			{buttonContent}
		</Box>
	) : (
		<Button
			disabled={props.isDisabled}
			onClick={goToModuleDashboard}
			sx={{
				width: '100%',
				height: rem(200),
				backgroundColor: '#404041',
				borderRadius: rem(12),
				textDecoration: 'none',
				appearance: 'none',
				border: 0,
				transition: '.3s ease-in-out',
				[theme.breakpoints.down('sm')]: {
					height: rem(150),
				},
				'&:disabled': {
					opacity: '.75',
					cursor: 'default',

					'&:hover': {
						backgroundColor: '#404041',
					},
				},

				'&:hover': {
					cursor: 'pointer',
					backgroundColor: '#343334',
				},
			}}
		>
			{buttonContent}
		</Button>
	);
});

export default SolutionCard;
