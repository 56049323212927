import { PaymentTemplate } from 'modules/clients/apiGateway/payments4/paymentTemplates';
import { PaymentAmount } from 'modules/clients/apiGateway/payments4/payments';
import moment from 'moment';
import * as yup from 'yup';
import { SubmitPaymentForm } from './submitPaymentDrawer';

export const ValueDateValidator: yup.StringSchema<string> = yup
	.string()
	.label('Value Date')
	.required()
	.defined()
	.test((value) => {
		const valueDate = moment(value);
		return (
			valueDate.isValid() &&
			!valueDate
				.startOf('day')
				.isBefore(moment().startOf('day').subtract(1, 'days'))
		);
	});

export const PaymentAmountValidator: yup.ObjectSchema<PaymentAmount> =
	yup.object({
		currencyCode: yup
			.string()
			.label('Currency Code')
			.required()
			.defined()
			.length(3),
		value: yup
			.number()
			.typeError('Amount is required')
			.label('Amount')
			.required()
			.defined()
			.positive(),
	});

export const SubmitPaymentFormValidator: yup.ObjectSchema<SubmitPaymentForm> =
	yup.object({
		template: yup
			.mixed<PaymentTemplate>()
			.label('Template')
			.nullable()
			.required(),
		instructedAmount: PaymentAmountValidator.required(),
		valueDate: ValueDateValidator,
		referenceData: yup
			.string()
			.label('Payment Information')
			.max(140)
			.nullable()
			.defined(),
	});
