import {
	ApprovalOutlined,
	FileCopyOutlined,
	PaidOutlined,
} from '@mui/icons-material';
import { Grid } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { Route, Switch, generatePath, useLocation } from 'react-router-dom';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { Layout } from 'shared/components/layout';
import { Navbar } from 'shared/components/navbar';
import { NavigationItemProps } from 'shared/components/navigation/navigationItem';
import { NavigationMenu } from 'shared/components/navigation/navigationMenu';
import SolutionPicker from 'shared/components/solutionPicker/solutionPicker';
import {
	ACCESS_DENIED_MESSAGING,
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from 'shared/constants/cannotDisplayMessaging';
import { paths } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import { T4Solution } from 'shared/t4Solutions';
import { PaymentApprovalsPageRoutes } from './paymentApprovals/paymentApprovalsPage';
import { PaymentStatusPageRoutes } from './paymentStatus/paymentStatusPage';
import { TemplatesPageRoutes } from './templates/templatesPage';

export const Payments4Layout: FC = () => {
	const location = useLocation();
	const { payments4 } = useUser();

	const isPayments4User = useMemo(
		() => Object.values(payments4).some((hasRole) => hasRole === true),
		[payments4],
	);

	const [selectedItem, setSelectedItem] = useState('');
	const [navigationItems, setNavigationItems] = useState<NavigationItemProps[]>(
		[],
	);

	useEffect(() => {
		const path = location.pathname;

		if (path.includes(paths.payments4.paymentStatus.href)) {
			setSelectedItem('payment-status');
		} else if (path.includes(paths.payments4.paymentApprovals.href)) {
			setSelectedItem('payment-approvals');
		} else if (path.includes(paths.payments4.templates.href)) {
			setSelectedItem('templates');
		} else {
			setSelectedItem('');
		}
	}, [location]);

	useEffect(() => {
		let items = [
			{
				id: 'payment-status-navigation',
				to: generatePath(paths.payments4.paymentStatus.href),
				label: 'Payment Status',
				icon: <PaidOutlined />,
				selected: selectedItem === 'payment-status',
			},
			{
				id: 'payment-approvals-navigation',
				to: generatePath(paths.payments4.paymentApprovals.href),
				label: 'Approvals',
				icon: <ApprovalOutlined />,
				selected: selectedItem === 'payment-approvals',
			},
			{
				id: 'templates-navigation',
				to: generatePath(paths.payments4.templates.href),
				label: 'Templates',
				icon: <FileCopyOutlined />,
				selected: selectedItem === 'templates',
			},
		];

		setNavigationItems(items);
	}, [selectedItem]);

	if (!isPayments4User)
		return (
			<Grid container sx={{ height: '100vh' }}>
				<CannotDisplay
					headingText={ACCESS_DENIED_MESSAGING.HEADING}
					bodyText={ACCESS_DENIED_MESSAGING.BODY}
					imageSrc={ACCESS_DENIED_MESSAGING.IMAGE}
					buttonText={ACCESS_DENIED_MESSAGING.BUTTON_TEXT}
					buttonHref={ACCESS_DENIED_MESSAGING.BUTTON_HREF}
				/>
			</Grid>
		);

	return (
		<Layout
			appBar={
				<Navbar
					logo={<SolutionPicker activeSolution={T4Solution.Payments4} />}
				/>
			}
			navElements={
				<NavigationMenu
					sections={[
						{
							navigationItems: navigationItems,
						},
					]}
				/>
			}
		>
			<Switch>
				<Route path={paths.payments4.paymentStatus.href}>
					<PaymentStatusPageRoutes />
				</Route>

				<Route path={paths.payments4.paymentApprovals.href}>
					<PaymentApprovalsPageRoutes />
				</Route>

				<Route path={paths.payments4.templates.href}>
					<TemplatesPageRoutes />
				</Route>

				<Route>
					<CannotDisplay
						headingText={NOT_FOUND_MESSAGING.HEADING}
						bodyText={NOT_FOUND_MESSAGING.BODY}
						imageSrc={NOT_FOUND_MESSAGING.IMAGE}
						buttonText={RETURN_TO_HOME}
						buttonHref={paths.root.href}
					/>
				</Route>
			</Switch>
		</Layout>
	);
};
