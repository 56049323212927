import { Card, Grid, Typography } from '@mui/material';
import { useReconciliationQuery } from 'features/cash4/_queries/useReconciliationQuery';
import { observer } from 'mobx-react-lite';
import { Reconciliation } from 'modules/clients/customer-api/src/api/cash4';
import moment from 'moment';
import { FC, useMemo } from 'react';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import { CurrencySource, defaultCurrencyCode } from 'utilities/currencyUtils';
import { NotesField } from '../_components/notesField';
import { ProjectedTransactionsGrid } from '../_components/projectedTransactionsGrid';
import { ReportedTransactionsGrid } from '../_components/reportedTransactionsGrid';
import {
	convertToPercentage,
	normalizeReconciliationStatus,
} from '../_hooks/useReconciliationCalculations';
import { SelectedTotalAlert } from '../_components/selectedTotal';
import { UnreconciledAmountAlert } from '../_components/unreconciledAmount';
import { formatReadDate } from 'shared/utilities/dateUtilities';

export type ReconciliationViewProps = {
	reconciliation: string | Reconciliation | undefined;
};

export const ReconciliationView: FC<ReconciliationViewProps> = observer(
	({ reconciliation: _reconciliation }) => {
		const { loading, data: reconciliation } =
			useReconciliationQuery(_reconciliation);

		const transactionCount = useMemo(() => {
			return reconciliation
				? reconciliation.summary.projectedCount +
						reconciliation.summary.reportedCount
				: 0;
		}, [reconciliation]);

		const reportedTransactions = useMemo(() => {
			return reconciliation?.reportedTransactions ?? [];
		}, [reconciliation?.reportedTransactions]);

		const projectedTransactions = useMemo(() => {
			return reconciliation?.projectedTransactions ?? [];
		}, [reconciliation?.projectedTransactions]);

		const [
			reportedCurrencySource,
			reportedCurrencyCode,
			reportedReportingCurrencyCode,
		] = useMemo(() => {
			let source = CurrencySource.Account;
			let accountCurrency = defaultCurrencyCode;
			let reportingCurrency = defaultCurrencyCode;
			const codes = new Set<string>();
			reportedTransactions.forEach((rt) => {
				codes.add(rt.number.accountCurrencyCode);
				if (
					rt.number.accountCurrencyCode &&
					rt.number.accountCurrencyCode !== accountCurrency
				) {
					accountCurrency = rt.number.accountCurrencyCode;
				}
				if (
					rt.number.reportingCurrencyCode &&
					rt.number.reportingCurrencyCode !== reportingCurrency
				) {
					reportingCurrency = rt.number.reportingCurrencyCode;
				}
			});
			if (codes.size >= 2) {
				source = CurrencySource.Reporting;
			}
			return [
				source,
				source === CurrencySource.Account ? accountCurrency : reportingCurrency,
				reportingCurrency,
			];
		}, [reportedTransactions]);

		const [projectedCurrencySource, projectedCurrencyCode] = useMemo(() => {
			let source = CurrencySource.Account;
			let accountCurrency = defaultCurrencyCode;
			let reportingCurrency = defaultCurrencyCode;
			const codes = new Set<string>();
			projectedTransactions.forEach((rt) => {
				codes.add(rt.amount.accountCurrencyCode);
				if (
					rt.amount.accountCurrencyCode &&
					rt.amount.accountCurrencyCode !== accountCurrency
				) {
					accountCurrency = rt.amount.accountCurrencyCode;
				}
				if (
					rt.amount.reportingCurrencyCode &&
					rt.amount.reportingCurrencyCode !== reportingCurrency
				) {
					reportingCurrency = rt.amount.reportingCurrencyCode;
				}
			});
			if (codes.size >= 2) {
				source = CurrencySource.Reporting;
			}
			return [
				source,
				source === CurrencySource.Account ? accountCurrency : reportingCurrency,
			];
		}, [projectedTransactions]);

		const [displayedCurrencySource, displayedCurrencyCode] = useMemo(() => {
			const source =
				reportedCurrencySource === projectedCurrencySource &&
				reportedCurrencyCode === projectedCurrencyCode
					? reportedCurrencySource
					: CurrencySource.Reporting;

			const code =
				reportedCurrencySource === projectedCurrencySource &&
				reportedCurrencyCode === projectedCurrencyCode
					? reportedCurrencyCode
					: reportedReportingCurrencyCode;

			return [source, code];
		}, [
			reportedCurrencySource,
			reportedCurrencyCode,
			projectedCurrencySource,
			projectedCurrencyCode,
			reportedReportingCurrencyCode,
		]);

		const projectedAmount = useMemo(() => {
			let result = 0;
			projectedTransactions.forEach((rt) => {
				const reportingAmount =
					rt.amount.reportingCurrencyAmount !== undefined
						? rt.amount.reportingCurrencyAmount
						: rt.amount.accountCurrencyAmount === 0
						? 0
						: NaN;
				result +=
					displayedCurrencySource === CurrencySource.Reporting
						? reportingAmount
						: rt.amount.accountCurrencyAmount;
			});
			return result;
		}, [projectedTransactions, displayedCurrencySource]);

		const isProjectedAmountValid = useMemo(() => {
			return !isNaN(projectedAmount);
		}, [projectedAmount]);

		const reportedAmount = useMemo(() => {
			let result = 0;
			reportedTransactions.forEach((rt) => {
				const reportingAmount =
					rt.number.reportingCurrencyAmount !== undefined
						? rt.number.reportingCurrencyAmount
						: rt.number.accountCurrencyAmount === 0
						? 0
						: NaN;
				result +=
					displayedCurrencySource === CurrencySource.Reporting
						? reportingAmount
						: rt.number.accountCurrencyAmount;
			});
			return result;
		}, [reportedTransactions, displayedCurrencySource]);

		const isReportedAmountValid = useMemo(() => {
			return !isNaN(reportedAmount);
		}, [reportedAmount]);

		const unreconciledAmount = useMemo(() => {
			return projectedAmount - reportedAmount;
		}, [projectedAmount, reportedAmount]);

		const isUnreconciledAmountValid = useMemo(() => {
			return !isNaN(unreconciledAmount);
		}, [unreconciledAmount]);

		const unreconciledVariance = useMemo(() => {
			if (unreconciledAmount === 0) {
				return 0;
			} else if (projectedAmount === 0) {
				return 1;
			} else {
				return unreconciledAmount / projectedAmount;
			}
		}, [unreconciledAmount, projectedAmount]);

		const isUnreconciledVarianceValid = useMemo(() => {
			return !isNaN(unreconciledVariance);
		}, [unreconciledVariance]);

		return (
			<Grid container item xs={12} spacing={1}>
				{/* Blue Title Banner */}
				<Grid item xs={12}>
					<Card
						sx={(theme) => ({
							backgroundColor: theme.palette.secondary.main,
						})}
					>
						<Grid container sx={{ gap: 2, padding: '1rem' }}>
							<Grid item xs={12}>
								<Typography variant="h3" align="center" sx={{ color: 'white' }}>
									{reconciliation
										? normalizeReconciliationStatus(reconciliation.status)
										: 'Unknown Status'}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography align="center" sx={{ color: 'white' }}>{`${
									isUnreconciledVarianceValid
										? convertToPercentage(unreconciledVariance)
										: '%'
								} variance | ${transactionCount} associated transactions | ${formatReadDate(
									reconciliation?.createdOn,
								)}`}</Typography>
							</Grid>
						</Grid>
					</Card>
				</Grid>

				{/* Reported Transactions Grid */}
				<Grid container item lg={6} md={12} sx={{ minHeight: 500 }}>
					<Grid
						container
						item
						direction="column"
						flexWrap="nowrap"
						sx={{
							height: '100%',
							gap: 2,
						}}
					>
						<Grid item>
							<Typography variant="h4">Reported</Typography>
						</Grid>
						<Grid item container xs={true}>
							<ActuallyPrettyGoodDataGridWrapper>
								<ReportedTransactionsGrid
									stonlyId="reported-items-view"
									tableKey="reported-items-view"
									loading={loading}
									reportedTransactions={reportedTransactions}
								/>
							</ActuallyPrettyGoodDataGridWrapper>
						</Grid>
						<Grid item xs="auto">
							<SelectedTotalAlert
								amount={reportedAmount}
								currencyCode={displayedCurrencyCode}
								currencySource={displayedCurrencySource}
								isAmountValid={isReportedAmountValid}
							/>
						</Grid>
					</Grid>
				</Grid>

				{/* Projected Transactions Grid */}
				<Grid container item lg={6} md={12} sx={{ minHeight: 500 }}>
					<Grid
						container
						item
						direction="column"
						flexWrap="nowrap"
						sx={{
							height: '100%',
							gap: 2,
						}}
					>
						<Grid item>
							<Typography variant="h4">Projected</Typography>
						</Grid>
						<Grid item container xs={true}>
							<ActuallyPrettyGoodDataGridWrapper>
								<ProjectedTransactionsGrid
									stonlyId="projected-items-view"
									tableKey="projected-items-view"
									loading={loading}
									projectedTransactions={projectedTransactions}
								/>
							</ActuallyPrettyGoodDataGridWrapper>
						</Grid>
						<Grid item xs="auto">
							<SelectedTotalAlert
								amount={projectedAmount}
								currencyCode={displayedCurrencyCode}
								currencySource={displayedCurrencySource}
								isAmountValid={isProjectedAmountValid}
							/>
						</Grid>
					</Grid>
				</Grid>

				{/* Unreconciled Amount Banner */}
				<Grid item xs={12}>
					<UnreconciledAmountAlert
						amount={unreconciledAmount}
						currencyCode={displayedCurrencyCode}
						currencySource={displayedCurrencySource}
						isAmountValid={isUnreconciledAmountValid}
						variance={unreconciledVariance}
					/>
				</Grid>

				{/* Notes */}
				<Grid item xs={12}>
					<NotesField defaultValue={reconciliation?.notes} disabled />
				</Grid>
			</Grid>
		);
	},
);
