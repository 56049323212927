import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { stonlyData } from 'stonly/functions';

export type TransactionAmountInfoModalProps = {
	isOpen: boolean;
	onCancel: () => void;
};

export const TransactionAmountInfoModal: FC<TransactionAmountInfoModalProps> =
	observer(({ isOpen, onCancel }) => (
		<Dialog
			open={isOpen}
			onClose={onCancel}
			data-stonlyid={stonlyData({ id: 'transaction-amount-info' })}
		>
			<DialogTitle>Amount</DialogTitle>
			<DialogContent>
				The positive or negative value is determined by the selection of inflow
				or outflow.
			</DialogContent>
			<DialogActions>
				<Button color="secondary" onClick={onCancel}>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	));
