import { CircularProgress, Grid, Typography } from '@mui/material';
import { PaymentParty } from 'modules/clients/apiGateway/payments4/payments';
import {
	CashFlowClass,
	GLCode,
} from 'modules/clients/customer-api/src/api/cash4';
import { FC, useMemo } from 'react';

export const PaymentPartyInformation: FC<{
	party: PaymentParty | null;
	partyType: 'Initiator' | 'Payee';
	isCategorizationLoading?: boolean;
	cashFlowClasses?: CashFlowClass[];
	glCodes?: GLCode[];
}> = ({
	party,
	partyType,
	isCategorizationLoading = false,
	cashFlowClasses = [],
	glCodes = [],
}) => {
	const rightColumnSx = {
		textAlign: 'right',
	};

	const hasCategorization = useMemo(
		() =>
			!!party?.cashFlowClassId ||
			!!party?.cashFlowTypeId ||
			!!party?.cashFlowSubtypeId ||
			!!party?.glCodeId,
		[party],
	);

	return (
		<Grid container item xs={12} sx={{ gap: 2 }}>
			<Grid container item xs={12}>
				<Grid item xs={5}>
					<Typography variant="body1" sx={{ fontWeight: 500 }}>
						{partyType} {partyType === 'Initiator' ? 'Entity' : ''} Name
					</Typography>
				</Grid>
				<Grid item xs sx={rightColumnSx}>
					<Typography variant="body1">{party?.displayName}</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={12}>
				<Grid item xs={5}>
					<Typography variant="body1" sx={{ fontWeight: 500 }}>
						{partyType} Counterparty
					</Typography>
				</Grid>
				<Grid item xs sx={rightColumnSx}>
					<Typography variant="body1">{party?.bank.displayName}</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={12}>
				<Grid item xs={5}>
					<Typography variant="body1" sx={{ fontWeight: 500 }}>
						{partyType} Account
					</Typography>
				</Grid>
				<Grid item xs sx={rightColumnSx}>
					<Typography variant="body1">{party?.accountDisplayName}</Typography>
				</Grid>
			</Grid>

			<Grid container item xs={12}>
				<PaymentBankAndAccountInformationBox
					bankCode={party?.bank.bankCode}
					operatingCountryCode={party?.bank.countryCode}
					routingCode={party?.bank.aba}
					paymentNumber={party?.accountNumber}
				/>
			</Grid>

			{hasCategorization && (
				<Grid container item xs={12}>
					<PaymentPartyCategorizationBox
						cashFlowClassId={party?.cashFlowClassId}
						cashFlowTypeId={party?.cashFlowTypeId}
						cashFlowSubtypeId={party?.cashFlowSubtypeId}
						glCodeId={party?.glCodeId}
						isCategorizationLoading={isCategorizationLoading}
						cashFlowClasses={cashFlowClasses}
						glCodes={glCodes}
					/>
				</Grid>
			)}

			<Grid container item xs={12}>
				<Grid item xs="auto">
					<Typography fontWeight={500}>
						Create Projected Transaction?
					</Typography>
				</Grid>
				<Grid item xs>
					<Typography sx={rightColumnSx}>
						{party?.needsProjectedTransaction ?? false ? 'Yes' : 'No'}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export const PaymentBankAndAccountInformationBox: FC<{
	bankCode?: string | null;
	operatingCountryCode?: string | null;
	routingCode?: string | null;
	paymentNumber?: string | null;
}> = ({ bankCode, operatingCountryCode, routingCode, paymentNumber }) => {
	const rightColumnSx = {
		textAlign: 'right',
	};

	return (
		<Grid
			item
			container
			xs={12}
			sx={(theme) => ({
				gap: 1,
				backgroundColor: theme.palette.cornflower[50],
				padding: '.5rem',
				borderRadius: '4px',
				fontSize: '.75rem',
				color: theme.palette.charcoal[500],
				marginTop: '0px !important',
			})}
		>
			<Grid container item xs={12}>
				<Grid item xs={5}>
					<Typography fontWeight={500}>Bank Code:</Typography>
				</Grid>
				<Grid item xs>
					<Typography sx={rightColumnSx}>{bankCode}</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={12}>
				<Grid item xs={5}>
					<Typography fontWeight={500}>Bank Country Code:</Typography>
				</Grid>
				<Grid item xs>
					<Typography sx={rightColumnSx}>{operatingCountryCode}</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={12}>
				<Grid item xs={5}>
					<Typography fontWeight={500}>Routing Code:</Typography>
				</Grid>
				<Grid item xs>
					<Typography sx={rightColumnSx}>{routingCode}</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={12}>
				<Grid item xs="auto">
					<Typography fontWeight={500}>Payment Account Number:</Typography>
				</Grid>
				<Grid item xs>
					<Typography sx={rightColumnSx}>{paymentNumber}</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export const PaymentPartyCategorizationBox: FC<{
	cashFlowClassId?: string | null;
	cashFlowTypeId?: string | null;
	cashFlowSubtypeId?: string | null;
	glCodeId?: string | null;
	isCategorizationLoading: boolean;
	cashFlowClasses: CashFlowClass[];
	glCodes: GLCode[];
}> = ({
	cashFlowClassId,
	cashFlowTypeId,
	cashFlowSubtypeId,
	glCodeId,
	isCategorizationLoading,
	cashFlowClasses,
	glCodes,
}) => {
	const cashFlowClass = useMemo(
		() => cashFlowClasses.find((x) => x.id === cashFlowClassId),
		[cashFlowClassId, cashFlowClasses],
	);

	const cashFlowType = useMemo(
		() => cashFlowClass?.types.find((x) => x.id === cashFlowTypeId),
		[cashFlowTypeId, cashFlowClass],
	);

	const cashFlowSubtype = useMemo(
		() => cashFlowType?.subtypes.find((x) => x.id === cashFlowSubtypeId),
		[cashFlowSubtypeId, cashFlowType],
	);

	const glCode = useMemo(
		() => glCodes.find((x) => x.id === glCodeId),
		[glCodeId, glCodes],
	);

	const rightColumnSx = {
		textAlign: 'right',
	};

	return (
		<Grid
			item
			container
			xs={12}
			sx={(theme) => ({
				gap: 1,
				backgroundColor: theme.palette.grey[50],
				padding: '.5rem',
				borderRadius: '4px',
				fontSize: '.75rem',
				color: theme.palette.charcoal[500],
				marginTop: '0px !important',
			})}
		>
			{isCategorizationLoading ? (
				<Grid
					item
					xs={12}
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<CircularProgress size={20} />
				</Grid>
			) : (
				<>
					<Grid container item xs={12}>
						<Grid item xs={5}>
							<Typography fontWeight={500}>Cash Flow Class:</Typography>
						</Grid>
						<Grid item xs>
							<Typography sx={rightColumnSx}>{cashFlowClass?.name}</Typography>
						</Grid>
					</Grid>
					<Grid container item xs={12}>
						<Grid item xs={5}>
							<Typography fontWeight={500}>Cash Flow Type:</Typography>
						</Grid>
						<Grid item xs>
							<Typography sx={rightColumnSx}>{cashFlowType?.name}</Typography>
						</Grid>
					</Grid>
					<Grid container item xs={12}>
						<Grid item xs={5}>
							<Typography fontWeight={500}>Cash Flow Subtype:</Typography>
						</Grid>
						<Grid item xs>
							<Typography sx={rightColumnSx}>
								{cashFlowSubtype?.name}
							</Typography>
						</Grid>
					</Grid>
					<Grid container item xs={12}>
						<Grid item xs={5}>
							<Typography fontWeight={500}>GL Code:</Typography>
						</Grid>
						<Grid item xs>
							<Typography sx={rightColumnSx}>{glCode?.code}</Typography>
						</Grid>
					</Grid>
				</>
			)}
		</Grid>
	);
};
