import { ExpandMore } from '@mui/icons-material';
import { Button, Collapse, Grid, Typography } from '@mui/material';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import {
	CashFlowClass,
	CashFlowSubtype,
	CashFlowType,
	GLCode,
} from 'modules/clients/customer-api/src/api/cash4';
import { FC, useMemo, useState } from 'react';
import {
	Control,
	Controller,
	UseFormSetValue,
	UseFormWatch,
} from 'react-hook-form';

export const CategorizationForm: FC<{
	type: 'initiator' | 'payee';
	control: Control<any, any>;
	watch: UseFormWatch<any>;
	setValue: UseFormSetValue<any>;
	isCategorizationLoading: boolean;
	cashFlowClasses: CashFlowClass[];
	glCodes: GLCode[];
}> = ({
	type,
	control,
	watch,
	setValue,
	isCategorizationLoading,
	cashFlowClasses,
	glCodes,
}) => {
	const cashFlowClassKey = useMemo(() => `${type}CashFlowClass`, [type]);
	const cashFlowTypeKey = useMemo(() => `${type}CashFlowType`, [type]);
	const cashFlowSubtypeKey = useMemo(() => `${type}CashFlowSubtype`, [type]);
	const glCodeKey = useMemo(() => `${type}GlCode`, [type]);

	const [isOpen, setIsOpen] = useState<boolean>(false);

	const selectedCashFlowClass = watch(cashFlowClassKey);

	const selectedCashFlowType = watch(cashFlowTypeKey);

	const cashFlowTypes = useMemo(
		() =>
			cashFlowClasses.find((x) => x.id === selectedCashFlowClass?.id)?.types ??
			[],
		[cashFlowClasses, selectedCashFlowClass],
	);

	const cashFlowSubtypes = useMemo(
		() => selectedCashFlowType?.subtypes ?? [],
		[selectedCashFlowType],
	);

	return (
		<Grid container item xs={12}>
			<Grid
				container
				item
				xs={12}
				sx={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Grid item xs="auto">
					<Typography variant="h4">Categorization</Typography>
				</Grid>
				<Grid item xs sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Button
						onClick={() => {
							if (!isOpen) setIsOpen(true);
							else setIsOpen(false);
						}}
						endIcon={
							<ExpandMore
								sx={{
									transition: 'rotate .25s',
									rotate: isOpen ? '180deg' : '0deg',
								}}
							/>
						}
						variant="text"
						color="secondary"
						sx={{ display: 'flex', justifyContent: 'center' }}
					>
						{isOpen ? 'Collapse' : 'Expand'}
					</Button>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Collapse in={isOpen}>
					<Grid item container xs={12} sx={{ gap: 1 }}>
						<Grid item xs={12}>
							<Controller
								name={cashFlowClassKey}
								control={control}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<T4Autocomplete<CashFlowClass>
										id="cash-flow-class"
										label="Cash Flow Class (CFC)"
										options={cashFlowClasses}
										isOptionEqualToValue={(option, value) =>
											option.id === value.id
										}
										getOptionLabel={(option) => option.name}
										value={value}
										onChange={(_: any, value: CashFlowClass | null) => {
											if (value?.id !== selectedCashFlowClass?.id) {
												setValue(cashFlowTypeKey, null);
												setValue(cashFlowSubtypeKey, null);
											}
											onChange(value);
										}}
										error={!!error}
										helperText={error && error.message}
										readOnly={isCategorizationLoading}
										loading={isCategorizationLoading}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name={cashFlowTypeKey}
								control={control}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<T4Autocomplete<CashFlowType>
										id="cash-flow-type"
										label="Cash Flow Type (CFT)"
										options={cashFlowTypes}
										isOptionEqualToValue={(option, value) =>
											option.id === value.id
										}
										getOptionLabel={(option) => option.name}
										value={value}
										onChange={(_: any, value: CashFlowType | null) => {
											if (value?.id !== selectedCashFlowType?.id)
												setValue(cashFlowSubtypeKey, null);
											onChange(value);
										}}
										error={!!error}
										helperText={error && error.message}
										readOnly={
											isCategorizationLoading || selectedCashFlowClass === null
										}
										loading={isCategorizationLoading}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name={cashFlowSubtypeKey}
								control={control}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<T4Autocomplete<CashFlowSubtype>
										id="cash-flow-subtype"
										label="Cash Flow Subtype (CFST)"
										options={cashFlowSubtypes}
										isOptionEqualToValue={(option, value) =>
											option.id === value.id
										}
										getOptionLabel={(option) => option.name}
										value={value}
										onChange={(_: any, value: CashFlowSubtype | null) =>
											onChange(value)
										}
										error={!!error}
										helperText={error && error.message}
										readOnly={
											isCategorizationLoading || selectedCashFlowType === null
										}
										loading={isCategorizationLoading}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name={glCodeKey}
								control={control}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<T4Autocomplete<GLCode>
										id="gl-code"
										label="GL Code"
										options={glCodes}
										isOptionEqualToValue={(option, value) =>
											option.id === value.id
										}
										getOptionLabel={(option) => option.code}
										value={value}
										onChange={(_: any, value: GLCode | null) => onChange(value)}
										error={!!error}
										helperText={error && error.message}
										readOnly={isCategorizationLoading}
										loading={isCategorizationLoading}
									/>
								)}
							/>
						</Grid>
					</Grid>
				</Collapse>
			</Grid>
		</Grid>
	);
};
