import {
	ProjectedTransaction,
	Reconciliation,
} from 'modules/clients/customer-api/src/api/cash4';
import { useMemo } from 'react';
import { TransactionListItem } from '../../transactions/models';

export enum ReconciliationStatus {
	Unreconciled = 'Unreconciled',
	PartiallyReconciled = 'PartiallyReconciled',
	Reconciled = 'Reconciled',
	Posted = 'Posted',
}

export function normalizeReconciliationStatus(status: string): string {
	return status === ReconciliationStatus.PartiallyReconciled
		? 'Partially Reconciled'
		: status === ReconciliationStatus.Posted
		? 'No Reconciliation'
		: status;
}

export function convertToPercentage(value: number): string {
	return `${Math.round((value ?? 0) * 10000) / 100}%`;
}

export function getCurrencyCode(
	reconciliation:
		| Reconciliation
		| [
				projectedTransactions: ProjectedTransaction[],
				reportedTransactions: TransactionListItem[],
		  ]
		| undefined,
) {
	let projectedTransactions: ProjectedTransaction[] = [];
	let reportedTransactions: TransactionListItem[] = [];
	if (Array.isArray(reconciliation)) {
		const [_projectedTransactions, _reportedTransactions] = reconciliation;
		projectedTransactions = _projectedTransactions;
		reportedTransactions = _reportedTransactions;
	} else {
		projectedTransactions = reconciliation?.projectedTransactions ?? [];
		reportedTransactions = reconciliation?.reportedTransactions ?? [];
	}

	const currencyCodes = new Map<string, number>();
	projectedTransactions.forEach((item) => {
		currencyCodes.set(
			item.amount.accountCurrencyCode,
			(currencyCodes.get(item.currencyCode) ?? 0) +
				item.amount.accountCurrencyAmount,
		);
	});
	reportedTransactions.forEach((item) => {
		if (item.currency) {
			currencyCodes.set(
				item.currency,
				(currencyCodes.get(item.currency) ?? 0) +
					item.number.accountCurrencyAmount,
			);
		}
	});

	let count = 0;
	let currencyCode = 'USD';
	currencyCodes.forEach((value, key) => {
		if (value > count) {
			count = value;
			currencyCode = key;
		}
	});

	return currencyCode;
}

export function getProjectedTransactionsTotal(reconciliation: Reconciliation) {
	return reconciliation.projectedTransactions.reduce(
		(acc, item) => acc + item.amount.accountCurrencyAmount,
		0,
	);
}

export function getreportedTransactionsTotal(reconciliation: Reconciliation) {
	return reconciliation.reportedTransactions.reduce(
		(acc, item) => acc + item.number.accountCurrencyAmount,
		0,
	);
}

export function calculateVariance(reconciliation: Reconciliation) {
	const projectedTransactionsTotal =
		getProjectedTransactionsTotal(reconciliation);
	const reconciliationDifference = calculateDifference(reconciliation);

	if (reconciliationDifference === 0) {
		// There's no difference; therefore, variance is 0.00%.
		return 0;
	} else if (projectedTransactionsTotal === 0) {
		// Projected transactions sum amount is zero; therefore, it's a 100% variance.
		return 1;
	} else {
		// Otherwise, calculate the variance from the projected transactions point of view.
		return reconciliationDifference / projectedTransactionsTotal;
	}
}

export function calculateDifference(reconciliation: Reconciliation) {
	return (
		getProjectedTransactionsTotal(reconciliation) -
		getreportedTransactionsTotal(reconciliation)
	);
}

export function calculateAmount(reconciliation: Reconciliation): number {
	const projectedReconciledAmount =
		reconciliation.projectedTransactions.reduce<number>(
			(acc, item) =>
				acc +
				(item.reconciliationStatus === ReconciliationStatus.Reconciled
					? item.amount.accountCurrencyAmount
					: 0),
			0,
		);
	const reportedReconciledAmount =
		reconciliation.reportedTransactions.reduce<number>(
			(acc, item) =>
				acc +
				(item.reconciliationStatus === ReconciliationStatus.Reconciled
					? item.number.accountCurrencyAmount
					: 0),
			0,
		);

	if (projectedReconciledAmount >= 0 && reportedReconciledAmount >= 0) {
		/*
		Projected				Reported
		$100 - Reconciled		$100 - Reconciled
		$100 - Partial			$50 - Reconciled
		$100 - Unreconciled		$25 - Reconciled
		Total Reconciled
		$100					$175
		** We take the greater of the two side.
		*/

		return Math.max(projectedReconciledAmount, reportedReconciledAmount);
	} else {
		/*
		Projected				Reported
		-$100 - Reconciled		-$100 - Reconciled
		-$100 - Partial			-$50 - Reconciled
		-$100 - Unreconciled	-$25 - Reconciled
		Total Reconciled
		-$100					-$175
		** We take the lesser of the two side.
		*/

		return Math.min(projectedReconciledAmount, reportedReconciledAmount);
	}
}

export type UseReconciliationCalculationsProps = {
	projectedAmount: number;
	reportedAmount: number;
	records: number;
	difference: number;
	variance: number;
	amount: number;
};

export function useReconciliationCalculations(
	reconciliation: Reconciliation | undefined,
): UseReconciliationCalculationsProps {
	const projectedAmount = useMemo<
		UseReconciliationCalculationsProps['projectedAmount']
	>(() => {
		return reconciliation === undefined
			? 0
			: getProjectedTransactionsTotal(reconciliation);
	}, [reconciliation]);

	const reportedAmount = useMemo<
		UseReconciliationCalculationsProps['reportedAmount']
	>(() => {
		return reconciliation === undefined
			? 0
			: getreportedTransactionsTotal(reconciliation);
	}, [reconciliation]);

	const records = useMemo<UseReconciliationCalculationsProps['records']>(() => {
		return (
			(reconciliation?.projectedTransactions?.length ?? 0) +
			(reconciliation?.reportedTransactions?.length ?? 0)
		);
	}, [
		reconciliation?.projectedTransactions?.length,
		reconciliation?.reportedTransactions?.length,
	]);

	const difference = useMemo<
		UseReconciliationCalculationsProps['difference']
	>(() => {
		return reconciliation === undefined
			? 0
			: calculateDifference(reconciliation);
	}, [reconciliation]);

	const variance = useMemo<
		UseReconciliationCalculationsProps['variance']
	>(() => {
		const getPercentage = (value: number) => {
			return Math.round(value * 10000) / 100;
		};
		return getPercentage(
			reconciliation === undefined ? 1 : calculateVariance(reconciliation),
		);
	}, [reconciliation]);

	const amount = useMemo<UseReconciliationCalculationsProps['amount']>(() => {
		return reconciliation === undefined ? 0 : calculateAmount(reconciliation);
	}, [reconciliation]);
	return {
		projectedAmount: projectedAmount,
		reportedAmount: reportedAmount,
		records: records,
		difference: difference,
		variance: variance,
		amount: amount,
	};
}
