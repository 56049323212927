import { useQuery } from '@tanstack/react-query';
import {
	CashFlowClass,
	GLCode,
} from 'modules/clients/customer-api/src/api/cash4';
import { useClients } from 'shared/hooks/useClients';

export const useGetCategorization = (enabled: boolean = true) => {
	const { customerApiClient } = useClients();

	const {
		isFetching: areCashFlowClassesFetching,
		data: cashFlowClasses,
		error: loadingCashFlowClassesError,
	} = useQuery<CashFlowClass[], Error>(
		['cashFlowClasses'],
		async () => {
			const response =
				await customerApiClient.api.cash4.getAllCashFlowClasses();
			if (response.status === 200 && response.data) return response.data.value;
			else throw new Error();
		},
		{
			refetchOnWindowFocus: false,
			enabled,
			staleTime: 60000,
		},
	);
	const {
		isFetching: areGlCodesFetching,
		data: glCodes,
		error: loadingGlCodesError,
	} = useQuery<GLCode[], Error>(
		['glCodes'],
		async () => {
			const response = await customerApiClient.api.cash4.getAllGlCodes();
			if (response.status === 200 && response.data) return response.data.data;
			else throw new Error();
		},
		{
			refetchOnWindowFocus: false,
			enabled,
			staleTime: 60000,
		},
	);

	return {
		isCategorizationLoading: areCashFlowClassesFetching || areGlCodesFetching,
		cashFlowClasses: cashFlowClasses ?? [],
		glCodes: glCodes ?? [],
		loadingCashFlowClassesError,
		loadingGlCodesError,
	};
};
