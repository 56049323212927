import CircleIcon from '@mui/icons-material/Circle';
import { Box, Tooltip, Typography } from '@mui/material';
import { GridCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { CopyToClipboardIconButton } from 'shared/components/copyToClipboardIconButton';
import { getFormattedDateTimeColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import { ImportFileType } from './types';

export const importFileTypes: ImportFileType[] = [
	{
		type: 'CSV',
		acceptedExtensions: ['.csv'],
	},
	{
		type: 'BAI2',
		acceptedExtensions: ['.txt', '.bai', '.bai2'],
	},
];

export const dataTypes = ['Cash Balances', 'Cash Transactions'];

export const MaxFileSizeInMB = 10;

export const errorMessages = (file: File) => {
	return {
		exceedsMaxFileSize: `${file.name} exceeds file size limit of ${MaxFileSizeInMB}MB and will not be imported.`,
	};
};

export const columns = [
	{
		...getFormattedDateTimeColumnDefinition(),
		field: 'uploadedDate',
		headerName: 'Imported Date',
		flex: 1,
	},
	{
		field: 'bankCode',
		headerName: 'Bank Code',
		type: 'string',
		flex: 1,
	},
	{
		field: 'fileType',
		headerName: 'File Type',
		type: 'string',
		flex: 1,
		valueGetter: ({ value }: GridValueGetterParams) => {
			if (value === 'Transactions') {
				return 'Cash Transactions';
			}
			if (value === 'Balances') {
				return 'Cash Balances';
			}
			return value;
		},
	},
	{
		field: 'name',
		headerName: 'File Name',
		flex: 1,
	},
	{
		field: 'status',
		headerName: 'Status',
		type: 'string',
		flex: 1,
		renderCell: ({ value, row }: GridCellParams) => {
			return (
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Tooltip title={row.errorMessage}>
						<Box sx={{ width: '100%', display: 'flex' }}>
							<CircleIcon
								sx={{
									marginRight: '5px',
									position: 'relative',
									top: '-1px',
									width: '15px',
								}}
								color={
									value === 'Complete'
										? 'success'
										: value === 'Processing'
										? 'info'
										: 'error'
								}
							/>
							<Typography fontSize={'inherit'}>{value}</Typography>
						</Box>
					</Tooltip>
					{row.errorMessage !== null && row.errorMessage !== undefined && (
						<CopyToClipboardIconButton
							value={row.errorMessage}
							iconButtonSize="small"
							iconSize="16px"
							tooltipSx={{
								marginLeft: 'auto',
							}}
						/>
					)}
				</Box>
			);
		},
	},
	{
		field: 'uploadedBy',
		headerName: 'Imported By',
		type: 'string',
		flex: 1,
	},
];

export const backgroundImage = `url("data:image/svg+xml,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' fill='none' rx='0' ry='0' stroke='%23AAA' stroke-width='4' stroke-dasharray='18' stroke-dashoffset='4' stroke-linecap='butt'/></svg>")`;
