/* eslint-disable mobx/missing-observer */
import {
	CheckCircleOutline,
	HighlightOff,
	InfoOutlined,
} from '@mui/icons-material';
import {
	CircularProgress,
	ClickAwayListener,
	IconButton,
	IconButtonProps,
	InputAdornment,
	Tooltip,
} from '@mui/material';
import { FC, useState } from 'react';

export type AdornmentType = 'loading' | 'success' | 'info' | 'error';

interface TooltipAdornmentProps {
	adornmentType?: AdornmentType;
	text: string;
	iconButtonProps?: Partial<IconButtonProps>;
	tooltipPlacement?:
		| 'bottom'
		| 'left'
		| 'right'
		| 'top'
		| 'bottom-end'
		| 'bottom-start'
		| 'left-end'
		| 'left-start'
		| 'right-end'
		| 'right-start'
		| 'top-end'
		| 'top-start';
}

export const TooltipAdornment: FC<TooltipAdornmentProps> = ({
	adornmentType = 'info',
	text,
	iconButtonProps,
	tooltipPlacement,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<InputAdornment position="start">
			<ClickAwayListener onClickAway={() => setIsOpen(false)}>
				<Tooltip
					open={isOpen}
					title={isOpen ? text : ''}
					placement={tooltipPlacement}
					arrow
					disableFocusListener
					disableHoverListener
					disableTouchListener
				>
					<IconButton
						{...iconButtonProps}
						onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
						size="small"
					>
						{adornmentType === 'loading' ? (
							<CircularProgress size={20} />
						) : adornmentType === 'success' ? (
							<CheckCircleOutline color="primary" />
						) : adornmentType === 'error' ? (
							<HighlightOff color="error" />
						) : (
							<InfoOutlined />
						)}
					</IconButton>
				</Tooltip>
			</ClickAwayListener>
		</InputAdornment>
	);
};
