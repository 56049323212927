import {
	Add,
	CancelOutlined,
	PaidOutlined,
	Refresh,
	WarningAmber,
} from '@mui/icons-material';
import {
	Box,
	CircularProgress,
	Divider,
	Grid,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Tab,
	Tabs,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	GridColDef,
	GridRenderCellParams,
	GridValueFormatterParams,
	GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import {
	Payment,
	PaymentStatusTypes,
} from 'modules/clients/apiGateway/payments4/payments';
import { T4ProblemDetails } from 'modules/clients/types';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { NoRowsOverlay } from 'shared/components/dataGrid/noRowsOverlay';
import T4DateRangePicker from 'shared/components/dateRangePicker/t4DateRangePicker';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4Link } from 'shared/components/t4Link';
import { T4View } from 'shared/components/t4View';
import {
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from 'shared/constants/cannotDisplayMessaging';
import { paths, validIdRegex } from 'shared/constants/paths';
import { useClients } from 'shared/hooks/useClients';
import { useQueue } from 'shared/hooks/useQueue';
import { useUser } from 'shared/hooks/useUser';
import { getDateColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import {
	DataGridColumnWidths,
	getOptionsMenuColDef,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { stonlyData } from 'stonly/functions';
import { formatCurrency } from 'utilities/currencyUtils';
import { flattenProblemDetails } from 'utilities/errors/errorUtils';
import { CancelPaymentModal } from '../cancelPaymentModal';
import { dateRangeDefault, useGetAllPayments } from '../hooks/usePayments';
import { PaymentDetailsDrawer } from '../paymentDetailsDrawer/paymentDetailsDrawer';
import { SubmitPaymentDrawer } from '../submitPaymentDrawer';
import {
	canCancelPayment,
	canGetPaymentBankStatus,
	getPaymentStatusText,
} from '../utilities';
import { PaymentStatusesTab } from './paymentStatusesTab';

const stonlyIds = {
	grid: 'payment-statuses',
	submitButton: 'submit-payment-button',
};

export const PaymentStatusPageRoutes: FC = () => {
	return (
		<Switch>
			<Route
				path={`${paths.payments4.paymentStatus.href}/:paymentId`.concat(
					validIdRegex,
				)}
				exact
			>
				<PaymentStatusPage />
			</Route>
			<Route path={paths.payments4.paymentStatus.href} exact>
				<PaymentStatusPage />
			</Route>

			<Route>
				<CannotDisplay
					headingText={NOT_FOUND_MESSAGING.HEADING}
					bodyText={NOT_FOUND_MESSAGING.BODY}
					imageSrc={NOT_FOUND_MESSAGING.IMAGE}
					buttonText={RETURN_TO_HOME}
					buttonHref={paths.root.href}
				/>
			</Route>
		</Switch>
	);
};

export const PaymentStatusPage: FC = () => {
	// #region State

	const { user, payments4 } = useUser();
	const { applicationApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const { paymentId: paymentIdQueryParam } = useParams<{
		paymentId: string | undefined;
	}>();

	const {
		isLoading,
		isFetching,
		data: payments,
		error,
		refetch,
		dateRange,
		setDateRange,
	} = useGetAllPayments();
	useEffect(() => {
		if (!isLoading && !isFetching && error?.message) {
			enqueueSnackbar(error.message, {
				variant: 'error',
			});
		}
	}, [isLoading, isFetching, error, enqueueSnackbar]);

	const [selectedPaymentId, setSelectedPaymentId] = useState<string | null>(
		null,
	);
	useEffect(() => {
		setSelectedPaymentId(paymentIdQueryParam ?? null);
	}, [paymentIdQueryParam]);

	const [paymentIdToCancel, setPaymentIdToCancel] = useState<string | null>(
		null,
	);

	const [isBankStatusLoading, setIsBankStatusLoading] = useState<{
		[key: string]: boolean;
	}>({});
	const [shouldRefetchPayments, setShouldRefetchPayments] =
		useState<boolean>(false);
	const { isProcessing, addTask } = useQueue();
	useEffect(() => {
		if (
			!isFetching &&
			!isLoading &&
			!!payments &&
			!isProcessing &&
			!Object.values(isBankStatusLoading).some((x) => x === true) &&
			shouldRefetchPayments
		) {
			refetch();
			setShouldRefetchPayments(false);
		}
	}, [
		isFetching,
		isLoading,
		payments,
		isProcessing,
		isBankStatusLoading,
		shouldRefetchPayments,
		refetch,
	]);

	const [tabIndex, setTabIndex] = useState<number>(0);

	const [isSubmitDrawerOpen, setIsSubmitDrawerOpen] = useState<boolean>(false);

	/**
	 * if user is coming from payment details drawer approvals tab with intention to create a new payment
	 * open submit payment drawer + clear the location state
	 */
	useEffect(() => {
		if (
			history.location?.state?.openSubmitPaymentDrawer === true &&
			isSubmitDrawerOpen === false
		) {
			setIsSubmitDrawerOpen(true);
			let state = { ...history.location.state };
			delete state.openSubmitPaymentDrawer;
			history.replace({ ...history.location, state });
		}
	}, [history, history.location, isSubmitDrawerOpen]);

	// #endregion

	// #region Test Get Status Function

	const getBankStatus = useCallback(
		async (paymentId: string) => {
			try {
				setIsBankStatusLoading((prev) => ({ ...prev, [paymentId]: true }));

				const response =
					await applicationApiClient.payments4.payments.getBankStatus(
						paymentId,
					);
				if (response.status === 200) {
					enqueueSnackbar('Successfully retrieved the payment status.', {
						variant: 'success',
					});
					return true;
				} else if (response.status === 400) {
					const errors = flattenProblemDetails(
						response.data as T4ProblemDetails,
					);
					if (!errors.length) throw new Error();

					enqueueSnackbar(errors[0], {
						variant: 'error',
					});
					return false;
				} else throw new Error();
			} catch (ex) {
				enqueueSnackbar(
					'Unable to retrieve the payment status at this time. Please try again later.',
					{
						variant: 'error',
					},
				);
				return false;
			} finally {
				setIsBankStatusLoading((prev) => ({ ...prev, [paymentId]: false }));
			}
		},
		[applicationApiClient, enqueueSnackbar],
	);

	// #endregion

	// #region Memoized Values

	const columns: GridColDef[] = useMemo(() => {
		return [
			{
				...getOptionsMenuColDef((params: GridRenderCellParams<Payment>) => (
					<OverflowMenu id="payment-options-menu">
						<MenuItem
							onClick={() => {
								setSelectedPaymentId(params.row.id);
								history.push(
									`${paths.payments4.paymentStatus.href}/${params.row.id}`,
								);
							}}
						>
							<ListItemText>View Details</ListItemText>
						</MenuItem>
						{canGetPaymentBankStatus(params.row) && (
							<MenuItem
								onClick={() =>
									addTask(async () => {
										if (await getBankStatus(params.row.id))
											setShouldRefetchPayments(true);
									})
								}
								disabled={isBankStatusLoading[params.row.id]}
							>
								<ListItemIcon>
									{isBankStatusLoading[params.row.id] ? (
										<CircularProgress size="20px" color="secondary" />
									) : (
										<Refresh color="secondary" />
									)}
								</ListItemIcon>
								<ListItemText
									sx={(theme) => ({
										'& .MuiTypography-root': {
											color: theme.palette.secondary.main,
										},
									})}
								>
									Get Status
								</ListItemText>
							</MenuItem>
						)}
						{canCancelPayment(params.row) && <Divider />}
						{canCancelPayment(params.row) && (
							<Tooltip
								title={
									!payments4.isPaymentApprover || !payments4.isPaymentInitiator
										? 'You do not have the correct permission to cancel payments. Contact your administrator if you require access.'
										: ''
								}
								placement="right"
								arrow
							>
								<span>
									<MenuItem
										onClick={() => setPaymentIdToCancel(params.row.id)}
										disabled={
											!payments4.isPaymentApprover ||
											!payments4.isPaymentInitiator
										}
									>
										<ListItemIcon>
											<CancelOutlined color="error" />
										</ListItemIcon>
										<ListItemText
											sx={(theme) => ({
												'& .MuiTypography-root': {
													color: theme.palette.error.main,
												},
											})}
										>
											Cancel Payment
										</ListItemText>
									</MenuItem>
								</span>
							</Tooltip>
						)}
					</OverflowMenu>
				)),
			},
			{
				field: 'currentStatus',
				headerName: 'Current Status',
				description: 'Current Status',
				type: 'string',
				minWidth: 200,
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					getPaymentStatusText(params.value),
				renderCell: (params: GridRenderCellParams<Payment>) => {
					if (
						params.row.currentStatus ===
						PaymentStatusTypes[PaymentStatusTypes.Submitted]
					) {
						return (
							<T4Link
								to={`${paths.payments4.paymentApprovals.href}/${params.row.id}/review`}
								sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
							>
								{params.value}
							</T4Link>
						);
					} else if (
						params.row.currentStatus ===
						PaymentStatusTypes[PaymentStatusTypes.Created]
					) {
						return (
							<Box
								sx={{
									display: 'flex',
									gap: 1,
									alignItems: 'center',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}
							>
								<WarningAmber color="warning" />
								<Typography variant="body2" noWrap sx={{ marginTop: '2px' }}>
									{params.value}
								</Typography>
							</Box>
						);
					} else if (
						params.row.currentStatus ===
						PaymentStatusTypes[PaymentStatusTypes.Transmitted]
					) {
						return (
							<Box
								sx={{
									display: 'flex',
									gap: 1,
									alignItems: 'center',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}
							>
								{isBankStatusLoading[params.row.id] && (
									<CircularProgress size="20px" color="secondary" />
								)}
								<Typography variant="body2" noWrap sx={{ marginTop: '2px' }}>
									{params.value}
								</Typography>
							</Box>
						);
					}

					return params.value;
				},
				sortComparator: (v1: string, v2: string) =>
					PaymentStatusTypes[v1 as keyof typeof PaymentStatusTypes] -
					PaymentStatusTypes[v2 as keyof typeof PaymentStatusTypes],
			},
			{
				...getDateColumnDefinition(),
				field: 'valueDate',
				headerName: 'Value Date',
				description: 'Value Date',
			},
			{
				...getDateColumnDefinition(),
				field: 'createdOn',
				headerName: 'Created Date',
				description: 'Created Date',
			},
			{
				field: 'initiatorName',
				headerName: 'Initiator',
				description: 'Initiator',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.initiator.displayName,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'initiatorAccountName',
				headerName: 'Initiator Account',
				description: 'Initiator Account',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.initiator.accountDisplayName,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'initiatorAccountNumber',
				headerName: 'Initiator Account Number',
				description: 'Initiator Account Number',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.initiator.accountNumber,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'initiatorBank',
				headerName: 'Initiator Bank',
				description: 'Initiator Bank',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.initiator.bank.displayName,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'payeeName',
				headerName: 'Payee',
				description: 'Payee',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.payee.displayName,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'payeeAccountName',
				headerName: 'Payee Account',
				description: 'Payee Account',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.payee.accountDisplayName,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'payeeAccountNumber',
				headerName: 'Payee Account Number',
				description: 'Payee Account Number',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.payee.accountNumber,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'payeeBank',
				headerName: 'Payee Bank',
				description: 'Payee Bank',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.payee.bank.displayName,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'paymentType',
				headerName: 'Payment Type',
				description: 'Payment Type',
				type: 'string',
				minWidth: 125,
			},
			{
				field: 'amount',
				headerName: 'Amount',
				description: 'Amount',
				type: 'number',
				valueFormatter: (params: GridValueFormatterParams) => {
					const row: Payment = params.api.getRow(params.id!)!;
					return formatCurrency(row.instructedAmount.value, {
						currency: row.instructedAmount.currencyCode ?? undefined,
						currencySign: 'accounting',
						currencyDisplay: 'narrowSymbol',
					})?.replace(row.instructedAmount.currencyCode!, '');
				},
				minWidth: DataGridColumnWidths.small,
			},
			{
				field: 'currency',
				headerName: 'CCY',
				description: 'CCY',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.instructedAmount.currencyCode,
				minWidth: 125,
			},
			{
				field: 'endToEndId',
				headerName: 'End-to-End ID',
				description: 'End-to-End ID',
				type: 'string',
				minWidth: DataGridColumnWidths.medium,
			},
		];
	}, [history, payments4, isBankStatusLoading, addTask, getBankStatus]);

	const CreatePaymentButton = useMemo(
		() => (
			<T4Button
				id={stonlyIds.submitButton}
				{...stonlyData({ id: stonlyIds.submitButton })}
				variant="outlined"
				startIcon={<Add />}
				onClick={() => setIsSubmitDrawerOpen(true)}
				disabled={!payments4.isPaymentInitiator || isFetching}
			>
				Create Payment
			</T4Button>
		),
		[payments4, isFetching],
	);

	const SubmitDrawer = useMemo(
		() => (
			<SubmitPaymentDrawer
				isOpen={isSubmitDrawerOpen}
				onClose={() => setIsSubmitDrawerOpen(false)}
				refetch={refetch}
			/>
		),
		[isSubmitDrawerOpen, refetch],
	);

	const PaymentRecordDetails = useMemo(
		() => (
			<PaymentDetailsDrawer
				paymentId={selectedPaymentId}
				onClose={() => {
					setSelectedPaymentId(null);
					history.push(paths.payments4.paymentStatus.href);
				}}
				refetch={refetch}
				setPaymentIdToCancel={setPaymentIdToCancel}
				isBankStatusLoading={
					isBankStatusLoading[selectedPaymentId ?? 'defaultKey']
				}
				getBankStatus={getBankStatus}
			/>
		),
		[history, selectedPaymentId, refetch, isBankStatusLoading, getBankStatus],
	);

	const CancelModal = useMemo(
		() => (
			<CancelPaymentModal
				paymentId={paymentIdToCancel}
				onClose={() => setPaymentIdToCancel(null)}
				refetch={refetch}
			/>
		),
		[paymentIdToCancel, refetch],
	);

	const SharedGridNoRowsOverlay = useMemo(
		() => () => (
			<NoRowsOverlay
				icon={PaidOutlined}
				heading={<Typography variant="h3">Nothing here to review.</Typography>}
				body={
					<Typography variant="body1">
						Check the All Payments tab or adjust the selected date range.
					</Typography>
				}
			/>
		),
		[],
	);

	const SharedGridHeading = useMemo(
		() => (
			<Grid
				container
				item
				xs="auto"
				sx={{
					display: 'flex',
					flexDirection: 'row',
					gap: 2,
				}}
			>
				<Grid item xs="auto">
					<T4DateRangePicker
						value={dateRange}
						onAccept={(dateRange) => setDateRange(dateRange)}
						showShortcuts
						shortcutResetDefaults={dateRangeDefault}
						disabled={isLoading || isFetching}
					/>
				</Grid>
				<Grid
					item
					xs
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						alignItems: 'center',
					}}
				>
					<Tooltip
						disableFocusListener
						disableTouchListener
						title={
							!payments4.isPaymentInitiator
								? 'You do not have the correct permission to submit payments. Contact your administrator if you require access.'
								: ''
						}
					>
						<span>{CreatePaymentButton}</span>
					</Tooltip>
				</Grid>
			</Grid>
		),
		[
			dateRange,
			isLoading,
			isFetching,
			setDateRange,
			payments4,
			CreatePaymentButton,
		],
	);

	const AllPayments = useMemo(() => {
		if (tabIndex !== 0) return null;

		const allPaymentsNoRowsOverlay = () => (
			<NoRowsOverlay
				icon={PaidOutlined}
				heading={
					<Typography variant="h3">
						No payments found within the selected date range. Try adjusting the
						date range or create a new payment.
					</Typography>
				}
				body={payments4.isPaymentInitiator ? CreatePaymentButton : undefined}
			/>
		);

		return (
			<PaymentStatusesTab
				tableId="all-payments"
				isLoading={isLoading || isFetching}
				payments={payments ?? []}
				columns={columns}
				gridNoRowsOverlay={allPaymentsNoRowsOverlay}
				gridHeading={SharedGridHeading}
			/>
		);
	}, [
		tabIndex,
		payments4,
		isLoading,
		isFetching,
		payments,
		columns,
		CreatePaymentButton,
		SharedGridHeading,
	]);

	const MyPayments = useMemo(
		() =>
			tabIndex === 1 ? (
				<PaymentStatusesTab
					tableId="my-payments"
					isLoading={isLoading || isFetching}
					payments={(payments ?? []).filter((x) =>
						x.statusHistory.some(
							(y) =>
								y.paymentStatusType ===
									PaymentStatusTypes[PaymentStatusTypes.Created] &&
								y.createdBy?.userId === user.sub,
						),
					)}
					columns={columns}
					gridNoRowsOverlay={SharedGridNoRowsOverlay}
					gridHeading={SharedGridHeading}
				/>
			) : null,
		[
			tabIndex,
			isLoading,
			isFetching,
			payments,
			user,
			columns,
			SharedGridNoRowsOverlay,
			SharedGridHeading,
		],
	);

	const PendingApprovalPayments = useMemo(
		() =>
			tabIndex === 2 ? (
				<PaymentStatusesTab
					tableId="pending-approval"
					isLoading={isLoading || isFetching}
					payments={(payments ?? []).filter(
						(x) =>
							x.currentStatus ===
							PaymentStatusTypes[PaymentStatusTypes.Submitted],
					)}
					columns={columns}
					gridNoRowsOverlay={SharedGridNoRowsOverlay}
					gridHeading={SharedGridHeading}
				/>
			) : null,
		[
			tabIndex,
			isLoading,
			isFetching,
			payments,
			columns,
			SharedGridNoRowsOverlay,
			SharedGridHeading,
		],
	);

	const InProgressPayments = useMemo(
		() =>
			tabIndex === 3 ? (
				<PaymentStatusesTab
					tableId="in-progress"
					isLoading={isLoading || isFetching}
					payments={(payments ?? []).filter(
						(x) =>
							x.currentStatus ===
								PaymentStatusTypes[PaymentStatusTypes.Approved] ||
							x.currentStatus ===
								PaymentStatusTypes[PaymentStatusTypes.Initiated] ||
							x.currentStatus ===
								PaymentStatusTypes[PaymentStatusTypes.Transmitted] ||
							x.currentStatus ===
								PaymentStatusTypes[PaymentStatusTypes.Pending],
					)}
					columns={columns}
					gridNoRowsOverlay={SharedGridNoRowsOverlay}
					gridHeading={SharedGridHeading}
				/>
			) : null,
		[
			tabIndex,
			isLoading,
			isFetching,
			payments,
			columns,
			SharedGridNoRowsOverlay,
			SharedGridHeading,
		],
	);

	const FailedPayments = useMemo(
		() =>
			tabIndex === 4 ? (
				<PaymentStatusesTab
					tableId="failed"
					isLoading={isLoading || isFetching}
					payments={(payments ?? []).filter(
						(x) =>
							x.currentStatus ===
								PaymentStatusTypes[PaymentStatusTypes.Failed] ||
							x.currentStatus ===
								PaymentStatusTypes[PaymentStatusTypes.Rejected],
					)}
					columns={columns}
					gridNoRowsOverlay={SharedGridNoRowsOverlay}
					gridHeading={SharedGridHeading}
				/>
			) : null,
		[
			tabIndex,
			isLoading,
			isFetching,
			payments,
			columns,
			SharedGridNoRowsOverlay,
			SharedGridHeading,
		],
	);

	const CompletedPayments = useMemo(
		() =>
			tabIndex === 5 ? (
				<PaymentStatusesTab
					tableId="completed"
					isLoading={isLoading || isFetching}
					payments={(payments ?? []).filter(
						(x) =>
							x.currentStatus ===
							PaymentStatusTypes[PaymentStatusTypes.Completed],
					)}
					columns={columns}
					gridNoRowsOverlay={SharedGridNoRowsOverlay}
					gridHeading={SharedGridHeading}
				/>
			) : null,
		[
			tabIndex,
			isLoading,
			isFetching,
			payments,
			columns,
			SharedGridNoRowsOverlay,
			SharedGridHeading,
		],
	);

	// #endregion

	return (
		<T4View
			header={
				<PageHeader
					id={pageHeaderStonlyIds.payments4.paymentStatusPage}
					title="Payment Status"
				/>
			}
		>
			<Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
				<Tabs
					indicatorColor="primary"
					value={tabIndex}
					onChange={(_, index) => setTabIndex(index)}
					sx={{ marginBottom: '1rem' }}
					variant="scrollable"
					scrollButtons="auto"
				>
					<Tab label="All Payments" tabIndex={0} />
					<Tab label="My Payments" tabIndex={1} />
					<Tab label="Pending Approval" tabIndex={2} />
					<Tab label="In Progress" tabIndex={3} />
					<Tab label="Failed" tabIndex={4} />
					<Tab label="Completed" tabIndex={5} />
				</Tabs>

				{AllPayments}
				{MyPayments}
				{PendingApprovalPayments}
				{InProgressPayments}
				{FailedPayments}
				{CompletedPayments}
			</Box>

			{SubmitDrawer}
			{CancelModal}
			{PaymentRecordDetails}
		</T4View>
	);
};
