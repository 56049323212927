import { Address, E4ObjectBase } from 'modules/clients/apiGateway/entity4';
import {
	Account,
	AccountNumbers,
} from 'modules/clients/apiGateway/entity4/accounts';
import {
	Counterparty,
	RoutingCodes,
} from 'modules/clients/apiGateway/entity4/counterparties';
import { LegalEntity } from 'modules/clients/apiGateway/entity4/legalentities';
import { Partner } from 'modules/clients/apiGateway/entity4/partners';
import {
	PaymentPriorityTypes,
	PaymentTypes,
} from 'modules/clients/apiGateway/payments4/payments';
import * as yup from 'yup';
import { CreatePaymentTemplateForm } from './createTemplateDrawer';
import { EditPaymentTemplateForm } from './useGetEditPaymentTemplateForm';
import { CounterpartyWithAccounts, PayeeTypes } from './utilities';
import {
	PaymentTemplate,
	PaymentTemplateStatusTypes,
} from 'modules/clients/apiGateway/payments4/paymentTemplates';
import {
	CashFlowClass,
	CashFlowSubtype,
	CashFlowType,
	GLCode,
} from 'modules/clients/customer-api/src/api/cash4';

export const TemplateValidatorOptions = { abortEarly: false };

export const E4ObjectBaseValidator: yup.ObjectSchema<E4ObjectBase> = yup.object(
	{
		id: yup.string().label('Id').required(),
		objectType: yup.string().required(),
		isApproved: yup.boolean().required(),
		code: yup.string().label('Code').required(),
		displayName: yup.string().label('Display Name').required().max(70),
	},
);

export const AddressValidator: yup.ObjectSchema<Address> = yup.object({
	type: yup.string().required().defined(),
	countryCode: yup
		.string()
		.trim()
		.label('Operating Address Country')
		.required('Operating Address Country')
		.defined('Operating Address Country'),
});

export const LegalEntityValidator: yup.ObjectSchema<LegalEntity> =
	E4ObjectBaseValidator.shape({
		isApproved: yup
			.boolean()
			.required()
			.oneOf([true], 'Entity is not approved'),
		objectType: yup
			.string()
			.required()
			.oneOf(['Entity'], 'Object must be a Legal Entity'),
		anglicizedLegalName: yup
			.string()
			.trim()
			.label('Anglicized Legal Name')
			.nullable()
			.required('Anglicized Legal Name'),
		operatingAddress: AddressValidator.label('Operating Address')
			.nullable()
			.required('Operating Address'),
	});

export const PartnerValidator: yup.ObjectSchema<Partner> =
	E4ObjectBaseValidator.shape({
		isApproved: yup
			.boolean()
			.required()
			.oneOf([true], 'Partner is not approved'),
		objectType: yup
			.string()
			.required()
			.oneOf(['Partner'], 'Object must be a Partner'),
		anglicizedLegalName: yup
			.string()
			.trim()
			.label('Anglicized Legal Name')
			.nullable()
			.required('Anglicized Legal Name'),
		operatingAddress: AddressValidator.label('Operating Address')
			.nullable()
			.required('Operating Address'),
	});

export const CounterpartyValidator: yup.ObjectSchema<Counterparty> =
	E4ObjectBaseValidator.shape({
		isApproved: yup
			.boolean()
			.required()
			.oneOf([true], 'Counterparty is not approved'),
		objectType: yup
			.string()
			.required()
			.oneOf(['Counterparty'], 'Object must be a Counterparty'),
		anglicizedLegalName: yup
			.string()
			.trim()
			.label('Anglicized Legal Name')
			.nullable()
			.required('Anglicized Legal Name'),
		bankCode: yup.string().label('Ultimate Parent').nullable().defined(),
		routingCodes: yup.mixed<RoutingCodes>().required(),
		operatingAddress: AddressValidator.label('Operating Address')
			.nullable()
			.required('Operating Address'),
	});

export const CounterpartyWithAccountsValidator: yup.ObjectSchema<CounterpartyWithAccounts> =
	CounterpartyValidator.shape({
		bankCode: yup
			.string()
			.trim()
			.label('Ultimate Parent')
			.nullable()
			.required('Ultimate Parent')
			.length(4),
		routingCodes: yup.object({
			highValueClearing: yup
				.string()
				.trim()
				.label('High Value Clearing Code')
				.nullable()
				.required('High Value Clearing Code')
				.max(9),
		}),
		accounts: yup.array().required(),
	});

export const AccountValidator: yup.ObjectSchema<Account> =
	E4ObjectBaseValidator.shape({
		isApproved: yup
			.boolean()
			.required()
			.oneOf([true], 'Account is not approved'),
		objectType: yup.string().required().defined().oneOf(['Account']),
		shortName: yup.string().label('Short Name').nullable().defined(),
		currencyCode: yup.string().label('Currency Code').nullable().defined(),
		accountNumbers: yup.mixed<AccountNumbers>().required(),
		heldWithCounterparty: yup
			.mixed<Counterparty>()
			.nonNullable('Account must have Counterparty holder')
			.required('Account must have Counterparty holder'),
	});

export const InitiatorAccountValidator: yup.ObjectSchema<Account> =
	AccountValidator.shape({
		accountNumbers: yup.object({
			pain001: yup
				.string()
				.trim()
				.label('PAIN001 Account Number')
				.nullable()
				.required('PAIN001 Account Number')
				.max(34),
			naturalAccountNumber: yup
				.string()
				.trim()
				.label('Natural Account Number')
				.nullable()
				.defined(),
		}),
	});

export const PayeeAccountValidator: yup.ObjectSchema<Account> =
	AccountValidator.shape({
		accountNumbers: yup.object({
			naturalAccountNumber: yup
				.string()
				.trim()
				.label('Natural Account Number')
				.nullable()
				.required('Natural Account Number')
				.max(34),
			pain001: yup
				.string()
				.trim()
				.label('PAIN001 Account Number')
				.nullable()
				.defined(),
		}),
	});

export const getCreatePaymentTemplateFormValidator = (
	templates: PaymentTemplate[],
): yup.ObjectSchema<CreatePaymentTemplateForm> =>
	yup.object().shape({
		name: yup
			.string()
			.trim()
			.label('Template Name')
			.max(160)
			.required()
			.defined()
			.test('non-unique', 'Template Name must be unique', function (value) {
				const list = templates.filter(
					(x) =>
						x.currentStatus !==
							PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Deleted] &&
						x.currentStatus !==
							PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Archived],
				);

				return !list
					.map((x) => x.name.toLowerCase())
					.includes(value.trim().toLowerCase());
			}),
		paymentType: yup
			.string()
			.label('Payment Type')
			.oneOf(Object.keys(PaymentTypes).filter((x) => !(parseInt(x) >= 0)))
			.required(),
		priorityType: yup
			.string()
			.label('Priority')
			.oneOf(
				Object.keys(PaymentPriorityTypes).filter((x) => !(parseInt(x) >= 0)),
			)
			.required(),
		currencyCode: yup.string().label('Payment CCY').required().length(3),
		referenceData: yup
			.string()
			.label('Payment Information')
			.max(140)
			.required()
			.nullable(),
		initiator: LegalEntityValidator.label('Initiator Entity Name')
			.nullable()
			.required(),
		initiatorBank: CounterpartyWithAccountsValidator.label(
			'Initiator Counterparty',
		)
			.nullable()
			.required(),
		initiatorAccount: InitiatorAccountValidator.label('Initiator Account')
			.nullable()
			.required(),
		initiatorNeedsProjectedTransaction: yup
			.boolean()
			.label('Create projected transaction')
			.required()
			.defined(),
		initiatorCashFlowClass: yup
			.mixed<CashFlowClass>()
			.label('Cash Flow Class')
			.nullable()
			.defined(),
		initiatorCashFlowType: yup
			.mixed<CashFlowType>()
			.label('Cash Flow Class')
			.nullable()
			.defined(),
		initiatorCashFlowSubtype: yup
			.mixed<CashFlowSubtype>()
			.label('Cash Flow Class')
			.nullable()
			.defined(),
		initiatorGlCode: yup
			.mixed<GLCode>()
			.label('Cash Flow Class')
			.nullable()
			.defined(),
		payeeType: yup.mixed<PayeeTypes>().label('Payee Type').required(),
		payee: yup
			.mixed<LegalEntity | Partner | Counterparty>()
			.label('Payee Name')
			.defined()
			.when('payeeType', ([payeeType], schema) => {
				if (payeeType === 'Entity')
					return schema
						.concat<LegalEntity, yup.AnyObject, any, ''>(LegalEntityValidator)
						.nullable()
						.required();
				else if (payeeType === 'Partner')
					return schema
						.concat<Partner, yup.AnyObject, any, ''>(PartnerValidator)
						.nullable()
						.required();
				else
					return schema
						.concat<Counterparty, yup.AnyObject, any, ''>(CounterpartyValidator)
						.nullable()
						.required();
			}),
		payeeBank: CounterpartyWithAccountsValidator.label('Payee Counterparty')
			.nullable()
			.required(),
		payeeAccount: PayeeAccountValidator.label('Payee Account')
			.nullable()
			.required(),
		payeeNeedsProjectedTransaction: yup
			.boolean()
			.label('Create projected transaction')
			.required()
			.defined(),
		payeeCashFlowClass: yup
			.mixed<CashFlowClass>()
			.label('Cash Flow Class')
			.nullable()
			.defined(),
		payeeCashFlowType: yup
			.mixed<CashFlowType>()
			.label('Cash Flow Class')
			.nullable()
			.defined(),
		payeeCashFlowSubtype: yup
			.mixed<CashFlowSubtype>()
			.label('Cash Flow Class')
			.nullable()
			.defined(),
		payeeGlCode: yup
			.mixed<GLCode>()
			.label('Cash Flow Class')
			.nullable()
			.defined(),
	});

export const getEditPaymentTemplateFormValidator = (
	templateId: string | null,
	templates: PaymentTemplate[],
): yup.ObjectSchema<EditPaymentTemplateForm> =>
	yup.object().shape({
		name: yup
			.string()
			.trim()
			.label('Template Name')
			.max(160)
			.required()
			.defined()
			.test('non-unique', 'Template Name must be unique', function (value) {
				return !templates
					.filter(
						(x) =>
							x.id !== templateId &&
							x.currentStatus !==
								PaymentTemplateStatusTypes[
									PaymentTemplateStatusTypes.Deleted
								] &&
							x.currentStatus !==
								PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Archived],
					)
					.map((x) => x.name.toLowerCase())
					.includes(value.trim().toLowerCase());
			}),
		initiator: LegalEntityValidator.label('Initiator Entity Name')
			.nullable()
			.required(),
		initiatorBank: CounterpartyWithAccountsValidator.label(
			'Initiator Counterparty',
		)
			.nullable()
			.required(),
		initiatorAccount: InitiatorAccountValidator.label('Initiator Account')
			.nullable()
			.required(),
		initiatorNeedsProjectedTransaction: yup
			.boolean()
			.label('Create projected transaction')
			.required()
			.defined(),
		initiatorCashFlowClass: yup
			.mixed<CashFlowClass>()
			.label('Cash Flow Class')
			.nullable()
			.defined(),
		initiatorCashFlowType: yup
			.mixed<CashFlowType>()
			.label('Cash Flow Class')
			.nullable()
			.defined(),
		initiatorCashFlowSubtype: yup
			.mixed<CashFlowSubtype>()
			.label('Cash Flow Class')
			.nullable()
			.defined(),
		initiatorGlCode: yup
			.mixed<GLCode>()
			.label('Cash Flow Class')
			.nullable()
			.defined(),
		payeeType: yup.mixed<PayeeTypes>().label('Payee Type').required(),
		payee: yup
			.mixed<LegalEntity | Partner | Counterparty>()
			.label('Payee Name')
			.defined()
			.when('payeeType', ([payeeType], schema) => {
				if (payeeType === 'Entity')
					return schema
						.concat<LegalEntity, yup.AnyObject, any, ''>(LegalEntityValidator)
						.nullable()
						.required();
				else if (payeeType === 'Partner')
					return schema
						.concat<Partner, yup.AnyObject, any, ''>(PartnerValidator)
						.nullable()
						.required();
				else
					return schema
						.concat<Counterparty, yup.AnyObject, any, ''>(CounterpartyValidator)
						.nullable()
						.required();
			}),
		payeeBank: CounterpartyWithAccountsValidator.label('Payee Counterparty')
			.nullable()
			.required(),
		payeeAccount: PayeeAccountValidator.label('Payee Account')
			.nullable()
			.required(),
		payeeNeedsProjectedTransaction: yup
			.boolean()
			.label('Create projected transaction')
			.required()
			.defined(),
		payeeCashFlowClass: yup
			.mixed<CashFlowClass>()
			.label('Cash Flow Class')
			.nullable()
			.defined(),
		payeeCashFlowType: yup
			.mixed<CashFlowType>()
			.label('Cash Flow Class')
			.nullable()
			.defined(),
		payeeCashFlowSubtype: yup
			.mixed<CashFlowSubtype>()
			.label('Cash Flow Class')
			.nullable()
			.defined(),
		payeeGlCode: yup
			.mixed<GLCode>()
			.label('Cash Flow Class')
			.nullable()
			.defined(),
		referenceData: yup
			.string()
			.label('Payment Information')
			.max(140)
			.required()
			.nullable(),
	});
